import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  images: any = [
    '../../../assets/images/about/aj.jpeg',
    '../../../assets/images/about/vc.jpeg',
    '../../../assets/images/about/gsir.jpg',
    '../../../assets/images/about/psir.jpg',
    '../../../assets/images/about/tsir.jpg',
    '../../../assets/images/about/naveen.jpeg',
    '../../../assets/images/about/hsir.jpeg',
    '../../../assets/images/about/smam.jpeg',
    '../../../assets/images/about/rmam.jpeg',
    '../../../assets/images/about/msir.jpeg',
    '../../../assets/images/about/gall (26).jpg',
    '../../../assets/images/about/gall (32).jpg',
    '../../../assets/images/about/gall (9).jpg',
    '../../../assets/images/about/nmam.jpeg',
    '../../../assets/images/about/deepak_sir.jpeg',
    '../../../assets/images/about/neetu_mam.jpeg',
    '../../../assets/images/about/arun_bansal.jpg',
  ]

  Welcome = [
    'SBIHE is exciting, exhilarating and enterprising! Not surprising then studying at SBIHE is popular and highly sought after.'
  ]

  Location = [
    'SBIHE committed to excellence in education, hava been set up amidst the Gangetic plains, in a clean and'
    + ' pollution free surrounding of a "sugar Bowl City" called Shamli, Distict Shamli, U.P. about 100 Kms'
    + ' from the national capital New Delhi. The institute are a two hours drive from North Delhi and centre point of'
    + ' cities like Meerut, Muzaffarnagar, Sharanpur, Panipat and Kernal. It is nestled is Saccharum suurrounding on'
    + ' Panipat Road and well away from the hustle and bustle, lans of traffic, pollution and chsos of Shamli City.'
  ]

  CHAIRMAN_MESSAGE = [
    '"EDUCATION IS THE MOST POWERFUL WEAPON WHICH YOU CAN USE TO CHANGE THE WORLD" '
    + ' Education is the base for building a good nation and we feel proud to be a contributor towards'
    + '  such a transformational notion.'
    + ' We get encouragement, looking at the bright and successful career of our students, which'
    + ' subsequently benefits the society.'
    + ' Not long ago education merely meant the transfer of knowledge from the teacher to the taught.'
    + ' Today education is much more than that. this is why Silver Bells Institute For Higher Education since its'
    + ' inception has been striving hard to impart the requisite knowledge and inculcate a new set of'
    + ' skills and attitude in our students.'
    + ' I wish a very successful career ahead to all the students of Silver Bells Institute For Higher Education and feel'
    + ' confident that they will make significant contribution to the organization they join in future.'
    + ' Faith is the strength by which a shattered world shall emerge into the light. All the best!'
  ]

  ARUN_BANSAL_MESSAGE = [
    'We have entered into the world of technology and advancement. In today'
    +'s  world change is inherent globally and Silver Bells Institute caters to this changing environment. '
    + 'We are trying to reach new heights in Management, Education, and Technology through our regular hard work and sincerity"'
  ]

  PRINCIPLE_MESSAGE = [
    'Life is full of efforts. to effort for something is the best key for success.'
    + ' and success is the only fruit which can lead us to the path of Peace'
    + ', happiness and prosperity. Effort is such a task which endows self contentment by polishing our'
    + ' personality.'
    + ' so to achieve success in life, some basic needs are required like knowledge, wisdom, patience,'
    + ' self confidence,'
    + ' mutual understanding and target.'
  ]

  DEEPAK_GARG_HOD_MESSAGE = [
 'I take this opportunity to welcome you from all walks of life and the world to be a part of BCA '
 + 'Department - Silver Bells Institute For HIgher Education. Our college have the distinction of being known as'
 + ' one of the pioneers in the field of IT education. Our aim is to remain at the forefront of learning,  '
 + ' teaching and research. We strive hard to promote academic excellence and are committed to'
 + ' creating an ambience for fostering innovation and creativity.'
 + ' We seek to be renowned for our distinctive curriculum emphasizing work- integrated learning,'
 + ' learning-based work, problem solving and cross-cultural skills.'
  ]


  MAYANK_GARG_HOD_MESSAGE = [
    'Welcome to the Department of Bachelor of Business Administration (BBA) '
    + 'The department has been successful in adopting innovative methods of imparting management education and preparing the students to meet the challenges of the industry. '
    + 'The Department’s strength lies in well qualified and experienced faculty who always thrive to deliver professional knowledge and impart interpersonal skills for the overall development of students to meet the expectations of the corporate world.  '
    + 'BBA Department offers a three year management program with a dual specialization consisting of 6 semesters. The curriculum consists of core papers with specialization such as Marketing, Finance and Human Resource Management'
     ]

  Established = [
    'Established in year 2004 , Silver Bells Institute for Higher Education is situated in Shamli'
     + ' in Uttar Pradesh state of India. It is affiliated to Chaudhary Charan'
    + ' Singh University. SBIHE, Shamli offers 4 courses across following streams namely IT, Management,'
    + ' Education, and across 4 degrees like B.Ed, B.COM, BBA, BCA.'
  ]

  // Stories = [
  //   'Silver Bells group runs Silver Bells Institute For Higher Education is one of the premier colleges of education.'
  //   +' Silver Bells Institute For Higher Education with its strong and efficient team'
  //   +'incessantly had been working since then to achieve a goal of imparting quality education.'
  //   +'It started as a sapling with a few tender branches'
  // ]

  Stories = [
    'Looking to the need of quality higher Education including Technical management & Teachers Education. The'
    +' institute was set up by CITIZEN WELFARE SOCITY (Regd) in 2004. It is affiliated to CCS University Meerut'
    +' and Approved by NCTE (GOI) , New Delhi.'
  ]
  Mission= [
    'The mission of Silver Bells Institute For Higher Education is to provide its students with'
    + ' the best professional Carrere prospects in emerging global workplace and'
    + ' to equip them for more effective and organised contribution in their chosen'
    + ' professional field.'
    + ' SBIHE Management gives Special Scholarship to Meritorious Students.'
  ]
  Vision= [
    'Our vision is. To enhance the educational system and No single youth is un-educational and Unemployed '
  ]


}
