<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Quick Facts </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-toolbar-row style="height: 100%;">

    <div class="container">
      <div class="row">
        <div class="col-sm-6" style="font-size:28px"><mat-icon style="color: rgb(255, 150, 13); font-size: 45px;">account_balance</mat-icon> &nbsp;&nbsp;&nbsp;&nbsp;Private</div>
        <div class="col-sm-6" style="font-size:28px"><mat-icon style="color: rgb(255, 150, 13); font-size: 45px;">done_all</mat-icon> &nbsp;&nbsp;&nbsp;&nbsp;Affiliated</div>
        <div class="col-sm-6" style="font-size:28px"><mat-icon style="color: rgb(255, 150, 13); font-size: 45px;">wc</mat-icon> &nbsp;&nbsp;&nbsp;&nbsp;Gender Accepted</div>
        <div class="col-sm-6" style="font-size:28px"><span style="color: rgb(255, 150, 13);">Estd. Year :</span> &nbsp;&nbsp;&nbsp;&nbsp; 2004</div>
      </div> <br>
    </div>

  </mat-toolbar-row>

</div>


<div class="container" style="margin-top: 25px;">
  <h1 class="font_style">ADMISSION RULES AND REGULATIONS</h1>

  <!--Section Start-->
  <mat-accordion>

    <!--Expension Panel Start-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          ELIGIBILITY CONDITIONS
        </mat-panel-title>
        <mat-panel-description>
          Silver Bells Institute For Higher Education
        </mat-panel-description>
      </mat-expansion-panel-header>
      <h5>1. You give to admission degree in base on 12th passed with 55 percent.</h5>
      <h5>2. Minimum 50% overall average</h5>
      <h5>3. You have passed the 12th class to anyone steam like that art,biology,maths,commerce. </h5>
      <h5>4. Apply for Scholarships and Other Financial Aid. ...</h5>

    </mat-expansion-panel>
  </mat-accordion>
  <!--Section End-->


  <!--Section Start-->
  <mat-accordion>


    <!--Expension Panel Start-->

    <!--Expension Panel Start-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          DOCUMENT REQUIREMENT PROCESS
        </mat-panel-title>
        <mat-panel-description>
          Silver Bells Institute For Higher Education
        </mat-panel-description>
      </mat-expansion-panel-header>

      <h5> 1. High School Mark sheet:- 2 Set Photocopy </h5>

      <h5> 2. Intermediate Mark sheet 2 Set Photocopy</h5>

      <h5> 3. Transfer Certificate Original + 1 Set Photocopy</h5>

      <h5> 4. Migration Certificate (Other than UP Board) Original + 1 Set Photocopy</h5>

      <h5> 5. Affidavit if there is any gap in study (on Rs. 10/- Non-Judicial stamp paper duly
        notarized) Original + 1 Set Photocopy</h5>

      <h5> 6. Category certificate such as OBC*/SC/ST, if applicable:- 2 Set Photocopyz</h5>

      <h5> 7. Aadhar Card Candidate:- 2 Set Photocopy</h5>

      <h5> 8. Character Certificate:- orignal Proof of residence
        Father/Mother (Voter ID card, Electricity bill, Aadhaar Card):- 1 Set Photocopy</h5>

    </mat-expansion-panel>
  </mat-accordion>
  <!--Section End-->


  <!--Section Start-->
  <mat-accordion>

    <!--Expension Panel Start-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          ADMISSION PROCEDURE
        </mat-panel-title>
        <mat-panel-description>
          Silver Bells Institute For Higher Education Shamli
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="tab_panel_text">
        <h5>It is that time of the year when all the young
          school pass-outs are looking for a suitable course
          and an apt college which will assist them in making
          their career aspirations come true, and Silver Bells Institute For Higher Education Shamli reputably fulfils this requirement.</h5>
      </div>



    </mat-expansion-panel>
  </mat-accordion>
  <!--Section End-->

  <!--Section Start-->
  <mat-accordion>


    <!--Expension Panel Start-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          REGISTRATION PROCESS
        </mat-panel-title>
        <mat-panel-description>
          Silver Bells Institute For Higher Education
        </mat-panel-description>
      </mat-expansion-panel-header>

      <h5>Two ways of Registration Process First online Process and Direct Admission.</h5>
      <h5> Online Registration to going on website of Silver Bells Institute For Higher Education Shamli.</h5>
      <h5> Do you went Direct admission to contect her Principal of Silver Bells Institute For Higher Education Shamli Dr.Vinod Kumar.</h5>
    </mat-expansion-panel>
    <!--Expension Panel Start-->
  </mat-accordion>
  <!--Section End-->

</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div>Courses Offered </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <button mat-stroked-button>BCA</button> &nbsp;
      <button mat-stroked-button>BBA</button>&nbsp;
      <button mat-stroked-button>B.Com</button>&nbsp;
      <button mat-stroked-button>B.Ed</button>&nbsp;
    </mat-toolbar-row>
  </mat-toolbar>
  <h5> &nbsp;&nbsp;&nbsp;Browse By Courses</h5> <br>

  <mat-toolbar-row style="height: 100%;">

    <div class="container">
      <div class="row">
        <div class="col-sm-3" style="float: left;"> BCA Seates: 120 seates &nbsp;&nbsp;&nbsp;<button mat-stroked-button routerLink='/bca'
          routerLinkActive="active"> > </button></div>
        <div class="col-sm-3" style="float: left;"> BBA Seates: 60 seates &nbsp;&nbsp;&nbsp;<button mat-stroked-button routerLink='/bba'
          routerLinkActive="active"> > </button></div>
        <div class="col-sm-3" style="float: left;"> B.Com Seates: 120 seates &nbsp;&nbsp;&nbsp;<button mat-stroked-button routerLink='/bcom'
          routerLinkActive="active"> > </button></div>
        <div class="col-sm-3" style="float: left;"> B.Ed Seates: 100 seates &nbsp;&nbsp;&nbsp;<button mat-stroked-button routerLink='/bed'
          routerLinkActive="active"> > </button></div>
      </div>
      <br>
    </div>
  </mat-toolbar-row>


</div>

<div class="modal-contents">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Facilities </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-toolbar-row style="height: 100%;">

    <div class="container">
      <div class="row">
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">book</mat-icon> &nbsp;&nbsp;&nbsp;Library</div>
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">sports_soccer</mat-icon> &nbsp;&nbsp;&nbsp;Sports</div>
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">emoji_food_beverage</mat-icon> &nbsp;&nbsp;&nbsp;Cafeteria </div>
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">directions_car</mat-icon> &nbsp;&nbsp;&nbsp; Transportation <br><br></div> 
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">computer</mat-icon> &nbsp;&nbsp;&nbsp; IT Infrastructur</div>
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">fitness_center</mat-icon> &nbsp;&nbsp;&nbsp;Laboratories</div>
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">event_seatf</mat-icon> &nbsp;&nbsp;&nbsp;Guest Room </div>
        <div class="col-sm-3" ><mat-icon style="color: rgb(255, 150, 13); ">fitness_center</mat-icon> &nbsp;&nbsp;&nbsp; Gym</div>
      </div>
      <br>

    </div>

  </mat-toolbar-row>
</div>
