import { Injectable } from '@angular/core';
import * as cryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private cryptoKey = 'india';

  constructor() { }

  encrypt(text: string) {
    let cipher = '';
    let key = '';
    try {
      try {
        key = this.cryptoKey;
        if (key == null || key === 'undefined' || key === '') {
          throw new Error('Invalid CryptoJS Key : ' + key);
        }
      } catch (error) {
        key = 'india';
      }

      cipher = cryptoJS.AES.encrypt(text, key) + '';

      return cipher + '';
    } catch (error) {
      throw new Error('encrypt:' + error);
    }
  }

  decrypt(text: string) {
    let deCipher = '';
    let key = '';
    try {
      try {
        key = this.cryptoKey;
        if (key == null || key === 'undefined' || key === '') {
          throw new Error('Invalid Crypto Key : ' + key);
        }
      } catch (error) {
        key = 'india';
      }

      deCipher = cryptoJS.AES.decrypt(text, key) + '';
      return deCipher;
    } catch (error) {
      throw new Error('decrypt:' + error);
    }
  }

  MD5(text) {
    let key;
    try {
      key = cryptoJS.MD5(text);
      return key + '';
    } catch (error) {
      throw new Error('md5:' + error);
    }
  }
}
