import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor() { }

  getBaseUrl() {
    let url = 'http://localhost:3000';
    return url;
  }


}
