<div class="modal-content">
  <mat-toolbar style="background-color: white;">
    <mat-toolbar-row>
      <marquee direction="right" style="font-size: 25px;color: blue;">
        BBA(Bachelor of Business Administration)</marquee>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Quick Facts </div>
      </span>
    </mat-toolbar-row>
    <mat-toolbar-row style="height: 100%;">

      <div class="container">
        <div class="row">
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13); size: 10vmin;">event_seat</mat-icon>Seats: 60</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">account_balance</mat-icon>Course-Duration: 3 years</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">school</mat-icon>Affilated-By: CCS University <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Meerut)</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">local_library</mat-icon>Medium: Regular</div>
        </div>
      </div>

    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Course Details </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;"> Mode : <b>Full Time</b> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Duration: <b> 3
      years</b>
    &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Offered by: <b>Chaudhary Charan Singh University, Meerut</b><br><br>
      <b>Bachelor of Business Administration</b> or BBA is one of the most popular bachelor degree
      programmes after class XII. The BBA course is the gateway to numerous job opportunities in a plethora of sectors
      like Marketing, Education, Finance, Sales, and Government to name just a few. Here are some important highlights
      about the BBA program/degree that you should know about: The 3-year professional undergraduate course in Business
      Management is open to students from all the three streams: Science, Arts and Commerce. The BBA course offers
      knowledge
      and training in management and leadership skills to prepare them for managerial roles and entrepreneurship.
      During the tenure of the course, candidates learn various aspects of business administration and management
      through class room lectures and practical projects like internship.
  </div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Eligibility Criteria </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
  A person, who have passed Higher Secondary/Intermediate (10+2) any other examination recognized as equivalent there to
  with "PCM" ,COMMERCE,Art with 50% or more than 50% marks, shall be eligible for the course.
  </div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Carrer Oppurtunities </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
     On completion of BBA, aspirants generally opt for MBA. However, candidates can also choose to explore job
    opportunities in various fields like banking, urban infrastructure & real estate management, business
    consultancies, advertising, manufacturing and in Government sectors to name a few. Some major job profiles
    one can opt for after completion of BBA are as follows:<br><br>

    <b>Human Resource Executive:</b> <br>
    The responsibility of a Human Resource Executive (HR Executive) includes ensuring that the strategies, methodology
    and HR programs are administered and the objectives of the organisation are in consistence with proficient benchmarks,
    state and government administrative prerequisites and laws. Also, planning,advising on HR strategies and
    overseeing human asset activities like enrollment and pay is the key responsibility of an HR Manager. The average
    salary of Human Resource Manager is Rs 4 lakh and upwards<br>

    <b>Marketing Executive:</b> <br>
    A Marketing Executive develops official statements and is responsible for promotion for the organisation’s
    products, conducting and analysing a competitive research, and establishing objectives, expectations and 
    results for marketing programmes. The average salary of Marketing Executive is Rs 3 lakh and upwards<br>

    <b>Marketing Manager:</b> <br>
    A Marketing Manager collaborates with product managers and aims at executing new/ modified programs for the
    establishment of improved strategies. Developing market research studies & analyze their finding,
    making the best use of marketing programs & campaigns for the organisation’s products and services are the
    responsibilities of a Marketing Manager. The average salary of a Marketing Manager is Rs 4.5 lakh and upwards<br>

    <b>Sales Executive:</b> <br> 
    The Sales Executive is required to design and implement strategies to increase company sales revenue and 
    expand the company’s base in the market. The average salary of a Sales Executive is Rs 3 lakh and upwards.<br>

    <b>Research and Development (R&D) Executive:</b> <br>
    A R&D Manager directs and manages the development & research programmes of an organisation in order to meet
    the needs of the organisation. Measuring the scope of research projects & making sure that they are delivered
    on time within the stipulated budget is the role of an R&D Manager.The average salary of a Sales Executive 
    is Rs 2 lakh and upwards.</div>
</div>



