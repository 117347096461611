<br>
<h3 style="color:darkblue;"> Silver Bells College For Higher Education Shamli </h3>
<br>
<mat-card class="login-mat-card">

  <form class="login-form" (submit)="onLogin(loginForm)" #loginForm="ngForm">

    <mat-form-field class="login-full-width">
      <input matInput type="text" name="userName" ngModel placeholder="User Name/ID" required #userName="ngModel">
      <mat-error *ngIf="userName.invalid">Please enter a valid User Name/ID.</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field class="login-full-width">
      <input matInput type="password" name="passkey" ngModel placeholder="Password/Key" required #passKey="ngModel">
      <mat-error *ngIf="passKey.invalid">Please enter a valid Password/Key.</mat-error>
    </mat-form-field>
    <br>
    <br>

    <div>
      <button mat-raised-button color="primary" type="submit">Login</button>

      <button mat-raised-button color="warn" style="float: right;" routerLink='/home' routerLinkActive="active"
        type="submit">Cancel</button>
    </div>

    <a href="" class="login-forgot" color="accent">Forgot User ID/Password?</a>
    <br>
  </form>

</mat-card>