<div style="background-color: #14bdee;">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer_social">

                    <ul>
                        <li><a href="https://www.facebook.com/sbiheshamli/"><i class="fa fa-facebook"
                                    aria-hidden="true">
                                    <img src="../../assets/images/footer/icons8-facebook-f-24.png">
                                </i></a></li>

                        <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true">
                                    <img src="../../assets/images/footer/icons8-play-button-24.png">
                                </i></a></li>

                        <li><a
                                href="https://www.instagram.com/explore/locations/1034225153/silver-bells-institute-for-higher-education/?hl=en"><i
                                    class="fa fa-instagram" aria-hidden="true">
                                    <img src="../../assets/images/footer/insta.png" style="height: 28px;width: 33px;">
                                </i></a></li>

                        <li><a href="#"><i class="fa fa-twitter" aria-hidden="true">
                                    <img src="../../assets/images/footer/icons8-stack-of-tweets-24.png">
                                </i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-sm-12" style="font-family: 'Roboto Slab', serif;color: white;">
                <div>Email Id:- Sbiheshamli@Gmail.com</div>
                <div>Office_no:-9760564500</div>
                <div> Kairana Panipat Road(Shamli) 247776</div>
            </div>
            <div class="col-lg-4 col-sm-6" style="font-family: 'Roboto Slab', serif;color: white;">
                <div class="row" style="cursor: pointer;">
                    <div class="col-sm-4 col-md-4" routerLink='/home' routerLinkActive="active">Home </div>
                    <div class="col-sm-4 col-md-4" routerLink='/aboutus' routerLinkActive="active">About </div>
                    <div class="col-sm-4 col-md-4" routerLink='/admission' routerLinkActive="active">Academic </div>
                    <div class="col-sm-4 col-md-4" routerLink='/bca' routerLinkActive="active">Course </div>
                    <div class="col-sm-4 col-md-4" routerLink='/infr' routerLinkActive="active">Campus </div>
                    <div class="col-sm-4 col-md-4" routerLink='/home' routerLinkActive="active">Downlode </div>
                    <div class="col-sm-4 col-md-4" routerLink='/feed' routerLinkActive="active">Feedback </div>
                    <div class="col-sm-4 col-md-4" routerLink='/contact' routerLinkActive="active">Contact </div>
                </div>
            </div>

            <div class="col-sm-3"></div>
            <div class="col-sm-12 col-lg-12" style="font-size: 14px;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               ---- CREATE BY SBIHE BCA BATCH (2017-20). AMAN JANGID, AMIT CHAUHAN, ABHISHEK CHAUHAN, ANKUSH KUMAR, JUNAID ----

            </div>
        </div>
    </div>
    <br>
</div>