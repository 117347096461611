<!-- About -->

<div class="about">
    <div class="container">

        <!-- <div class="row">
            <div class="col">
                <div class="section_title_container text-center" class="main_title">
                    <div>
                        <h1 class="feature_title">We Make Institute More Affordable,<br>We Make The Dream More Achievable.
                        </h1>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col">
                <div class="text-center" class="main_title">
                    <div style="font-size: 54px;font-family: serif;font-style: oblique;color: Magenta;"> Welcome To SBIHE Shamli
                    </div>
                </div>
            </div>
        </div>
        <div style="font-size: 20px;color: blue;"><i>{{Welcome[0]}}</i></div>

        <div class="section-top-border">
            <h3 class="mb-30 title_color font" > Location....</h3>
            <div class="row">
                <div class="col-md-12 mt-sm-20 left-align-p">
                    <p style="font-size: 20px;"> {{Location[0]}} </p>
                </div>

            </div>
        </div>


        <div class="section-top-border">
            <h3 class="mb-30 title_color font"> CHAIRMAN'S MESSAGE....</h3>
            <div class="row">
                <div class="col-md-3">
                    <img [src]="images[0]" class="img-fluid">
                </div>
                <div class="col-md-9 mt-sm-20 left-align-p">
                    <p style="font-size: 20px;"> {{CHAIRMAN_MESSAGE[0]}} </p>
                    <p class="text-right" style="color: blue;font-size:20px;"> Mr. Ajay Sangal</p>
                </div>

            </div>
        </div>

        <div class="section-top-border text-right">
            <h3 class="mb-30 title_color font">SECRETARY MESSAGE....</h3>
            <div class="row">
                <div class="col-md-9">
                    <p class="text-left" style="font-size: 20px;"> {{ARUN_BANSAL_MESSAGE[0]}} </p>
                    <p class="text-right" style="color: blue;font-size: 20px;"><br><br><br> Mr. ARUN BANSAL </p>
                </div>
                <div class="col-md-3">
                    <img [src]="images[16]"  alt="" class="img-fluid">
                </div>
            </div>
        </div>

        <div class="section-top-border text-left">
            <h3 class="mb-30 title_color font">PRINCIPAL MESSAGE....</h3>
            <div class="row">
                <div class="col-md-3">
                    <img [src]="images[1]" style="width: 190px;height: 240px;" alt="" class="img-fluid">
                </div>
                <div class="col-md-9">
                    <p style="font-size: 20px;"> {{PRINCIPLE_MESSAGE[0]}}
                        <br>
                        => The roots of education are bitter, but the fruit is sweet."
                    </p>
                    <p class="text-left" style="color: blue;font-size: 20px;"> Dr. Vinod Kumar<br> MA , B.Ed. , M.Ed. , P.Hd. <br>
                    9720320420</p>
                </div>
            </div>
        </div>

        <div class="section-top-border text-right">
            <h3 class="mb-30 title_color font">HOD OF BCA MESSAGE....</h3>
            <div class="row">
                <div class="col-md-9">
                    <p class="text-left" style="font-size: 20px;"> {{DEEPAK_GARG_HOD_MESSAGE[0]}}</p>
                    <p class="text-right" style="color: blue;font-size: 20px;"> Dr. Deepak Garg <br> B.Sc , B.Ed , MCA , M.Tech , M.Phil , UGC-NET <br>
                    9756146765 </p>
                </div>
                <div class="col-md-3">
                    <img [src]="images[14]"  alt="" class="img-fluid">
                </div>
            </div>
        </div>

        <div class="section-top-border text-left">
            <h3 class="mb-30 title_color font">HOD OF BBA MESSAGE....</h3>
            <div class="row">
                <div class="col-md-3">
                    <img [src]="images[9]" style="width: 200px;height: 240px;" alt="" class="img-fluid">
                </div>
                <div class="col-md-9">
                    <p class="text-left" style="font-size: 20px;"> {{MAYANK_GARG_HOD_MESSAGE[0]}}
                      
                    </p>
                    <p class="text-left" style="color: blue;font-size: 20px;">Dr. Mayank Sangal<br> BBA, MBA, M.COM, P.HD, LLB, PGDEM <br>
                    9557095573</p>
                </div>

            </div>
        </div>

        <!-- <div class="section-top-border text-left">
            <h3 class="mb-30 title_color">Established....</h3>
            <div class="row">
                <div class="col-md-12">
                    <p class="text-left" style="font-size: 20px;"> {{Established[0]}}  </p>
                    <p class="text-left"> <b class="blink blink-one" style="color: blue;font-size: 24px; "><i>College going on under citizen welfare society, Shamli</i></b><br>
                </div>
            </div>
        </div> -->

        <div class="row about_row">
            <!-- About Item -->
            <div class="col-lg-4 about_col about_col_left">
                <div class="about_item">
                    <div class="about_item_image"><img [src]="images[10]" alt=""></div>
                    <div class="about_item_title"><a href="#">Our Stories</a></div>
                    <div class="about_item_text">
                        <p style="font-size: 20px;"> {{Stories[0]}} </p>
                    </div>
                </div>
            </div>

            <!-- About Item -->
            <div class="col-lg-4 about_col about_col_middle">
                <div class="about_item">
                    <div class="about_item_image"><img [src]="images[11]" alt=""></div>
                    <div class="about_item_title"><a href="#">Our Mission</a></div>
                    <div class="about_item_text">
                        <p style="font-size: 20px;"> {{Mission[0]}} </p>
                    </div>
                </div>
            </div>
            

            <!-- About Item -->
            <div class="col-lg-4 about_col about_col_right">
                <div class="about_item">
                    <div class="about_item_image"><img [src]="images[12]" alt=""></div>
                    <div class="about_item_title"><a href="#">Our Vision</a></div>
                    <div class="about_item_text">
                        <p style="font-size: 20px;"> {{Vision[0]}} </p>
                    </div>
                </div>
            </div>

        </div>


        <!--================Team Area =================-->
        <section class="team_area p_120">
            <div class="container">
                <div class="main_title">
                    <h2>Meet Our Faculty Members</h2>
                    <p>Top Class Faculty in SBIHE more than 80% Faculty are post graduated and
                        well expierence provides to the students </p>
                </div>
                <div class="row team_inner">

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[1]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Dr. Vinod Kumar</h4>

                            </div>
                        </div>
                    </div>
                   
                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[3]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Mr. Pankaj Jindal</h4>

                            </div>
                        </div>
                    </div>
                    

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[8]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Smt. Rajni</h4>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[15]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Smt. Neetu Singh</h4>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[6]" alt="">

                            </div>
                            <div class="team_name">
                                <h4> Dr.Deepak Garg</h4>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[2]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Mr. Gautom Kashyap</h4>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[7]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Ms. Shelley Jindal</h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[13]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Ms. Niharika</h4>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[9]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Dr. Mayank Sangal</h4>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="team_item">
                            <div class="team_img">
                                <img class="rounded-circle" [src]="images[5]" alt="">

                            </div>
                            <div class="team_name">
                                <h4>Mr. Naveen</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!--================End Team Area =================-->
    </div>
</div>