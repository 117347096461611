<div class="container">
    <div class="row">
        <div class="col-sm-4">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar [style.background-image]="'url(../../../assets/images/credit/aman.jpg)'"
                        style="background-size: cover;">
                    </div>
                    <mat-card-title>Aman Jangid (Leader)</mat-card-title>
                    <mat-card-subtitle>
                        <b>BCA (2017-2020)<br></b>
                        <b><span style="color: brown"> Occupation : </span>Student, Software trainee </b><br>
                        <b><span style="color: brown"> ROLL NO. : </span>1708431742</b>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-title> <img style="height:400px;width: 100%;" [src]="images[0]" class="img-carousel">
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-4">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar [style.background-image]="'url(../../../assets/images/credit/amit.jpeg)'"
                        style="background-size: cover;">
                    </div>
                    <mat-card-title>Amit chauhan</mat-card-title>
                    <mat-card-subtitle>
                        <b>BCA (2017-2020)<br></b>
                        <b><span style="color: brown"> Occupation : </span>Student</b><br>
                        <b><span style="color: brown"> ROLL NO. : </span>1708431743</b>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-title> <img style="height:400px;width: 100%;" [src]="images[1]" class="img-carousel">
                </mat-card-title>
            </mat-card>
        </div>

        <div class="col-sm-4">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar [style.background-image]="'url(../../../assets/images/credit/abhi.jpeg)'"
                        style="background-size: cover;">
                    </div>
                    <mat-card-title>Abhiskek chauhan</mat-card-title>
                    <mat-card-subtitle>
                        <b>BCA (2017-2020)<br></b>
                        <b><span style="color: brown"> Occupation : </span>Student</b><br>
                        <b><span style="color: brown"> ROLL NO. : </span>1708431741</b>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-title> <img style="height:400px;width: 100%;" [src]="images[2]" class="img-carousel">
                </mat-card-title>
            </mat-card>
        </div>

        <div class="col-sm-4">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar [style.background-image]="'url(../../../assets/images/credit/ankush.jpeg)'"
                        style="background-size: cover;">
                    </div>
                    <mat-card-title>Ankush</mat-card-title>
                    <mat-card-subtitle>
                        <b>BCA (2017-2020)<br></b>
                        <b><span style="color: brown"> Occupation : </span>Student</b><br>
                        <b><span style="color: brown"> ROLL NO. : </span>1708431744</b>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-title> <img style="height:400px;width: 100%;" [src]="images[3]" class="img-carousel">
                </mat-card-title>
            </mat-card>
        </div>

        <div class="col-sm-4">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar [style.background-image]="'url(../../../assets/images/credit/junaid.jpeg)'"
                        style="background-size: cover;">
                    </div>
                    <mat-card-title>Junaid</mat-card-title>
                    <mat-card-subtitle>
                        <b>BCA (2017-2020)<br></b>
                        <b><span style="color: brown"> Occupation : </span>Student</b><br>
                        <b><span style="color: brown"> ROLL NO. : </span>1708431752</b>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-title> <img style="height:400px;width: 100%;" [src]="images[4]" class="img-carousel">
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-4"></div>
    </div>
</div>