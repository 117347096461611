<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black;">
    <mat-toolbar-row>
      <marquee direction="right" style="font-size: 25px;color: blue; ">B.Ed(Bachelor in Education)</marquee>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black;">
    <span style="font-size: 28px;text-align: center;">
      <div> Quick Facts </div>
    </span>
  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black;height: 100%;">

    <div class="container">
      <div class="row">
        <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13); size: 10vmin;">event_seat</mat-icon>Seats: 100</div>
        <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">account_balance</mat-icon>Course-Duration: 2 years</div>
        <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">school</mat-icon>Affilated-By: CCS University <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Meerut)</div>
        <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">local_library</mat-icon>Medium: Regular</div>
      </div>
    </div>

  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Course Details </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;"> Mode : <b>Full Time</b> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Duration: <b> 2
      years</b>
    &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Offered by: <b>Chaudhary Charan Singh University, Meerut</b><br> <br>
    Considered to be the noblest profession, Teaching has always been one of the most popular career choices among
    students. However, to become a teacher at the pre-nursery, nursery, primary, secondary and senior secondary
    levels in schools or to become a professor at the college/university level, aspirants need to possess the
    right qualifications. B.Ed is the bachelor’s degree undertaken to take up teaching as a profession. However,
    it is worth knowing that B.Ed or Bachelor of Education is not an undergraduate degree and to pursue this
    course one needs to have completed his/her graduation. Thus, B.Ed is a professional course and right after
    completing this course, students can get a job at the school level. The B.Ed course duration is typically two years.
  </div>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Eligibility Criteria </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
    <b>Educational Qualification:</b> <br>
    Candidates need to have completed their graduation in any stream (that is,
    Arts, Science or Commerce) in order to fulfill B.Ed eligibility criteria. However, most popular B.Ed colleges
    allow candidates to take part in their admission process for B.Ed course if they have graduated with at least
    50% aggregate marks at the UG level.<br>

    <b>Age Limit:</b> <br>
    For B.Ed admissions in a majority of colleges, there is no age bar. However, admission process of
    some B.Ed colleges require candidates to be minimum 19 years in age.
  </div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Carrer Oppurtunities </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
    On completing Bachelor of Education, aspirants can become teachers at the primary, secondary and senior secondary
    levels in schools. In order to be hired as an Assistant Professor or Professor at the college/university level,
    candidates need to appear for Teacher Eligibility Tests like CTET, APTET, TSTET or UGC NET.
    Candidates with a B.Ed degree can apply for jobs at all government and private schools in India.
  </div>
</div>