import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from '../../core/services/login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  private userName;
  private passKey;
  private loginResponse;

  constructor(public loginService: LoginService) {}

  ngOnInit() {
  }

  onLogin(loginForm: NgForm )  {

    if (loginForm.invalid) {
      return;
    }
    this.userName = loginForm.value.userName;
    this.passKey = loginForm.value.passKey;

    this.loginResponse = this.loginService.login(this.userName, this.passKey);
    console.log(this.loginResponse);
    status = JSON.stringify(this.loginResponse);
    // loginForm.resetForm();
  }


  login_click() {
    let userid = '';
    let pwd = '';
    let result = '';


    result = this.loginService.login(userid, pwd);

    // get user n pass in variables
    alert('login button clicked : ' + userid + ' : ' + pwd + ' : ' + result);

  }

}
