import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  document = [
    '../../assets/images/document/NBC.pdf',
    '../../assets/images/document/Society member.pdf',
    '../../assets/images/document/Land Use certificate.pdf',
    '../../assets/images/document/Land Document.pdf',
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
