import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.css']
})
export class InfrastructureComponent implements OnInit {


  images = [
    '../../assets/images/infr/carousel-3.jpg',
    '../../assets/images/infr/clg.jpg',
    '../../assets/images/infr/computer.jpg',
    '../../assets/images/infr/library2.jpg',
    '../../assets/images/infr/cent.jpg',
    '../../assets/images/infr/medical.jpg',
    '../../assets/images/infr/carousel-13.jpg',
    '../../assets/images/infr/bus.jpg',
    '../../assets/images/infr/sport.jpg'
  ];

  Infra = [
    'In order to achieve its ambition of being a world-class education provider, Silver Bells Institute For Higher Education  (SBIHE)'
   + ' is committed to significant enhancement of its facilities and buildings, essential for world-class research and all-round'
   + ' development of students. The Institute has an extremely modern and state-of-the-art infrastructure'
   + ' that goes a long way in facilitating a number of services for the students as well as the staff members.'
  ]

  class = [
    'The spacious, amphitheatre-style classrooms in the institute provide the most conducive atmosphere for'
     + ' dynamic and focused discussions. They have been designed to bring together analysis with action and are'
     + ' augmented with integrated audio–visual teaching aids for lectures, presentations etc. Special sessions'
     + ' and classes are also held on business etiquettes, negotiation skills, effective communication, leadership,'
     + ' work and analytical skills.'
  ]

  computer = [
    'All CU campuses are inter-connected through a highly secure virtual private network, where personal'
     + ' computers (PC) are inter-connected to the Internet through broadband connection. The campuse'
     + ' has state-of-the-art computer centres that provide computing facilities comprising of the latest'
     + ' computer machines linked to a wide range of software, communication and print services.'
     + ' Students in many programs are provided with wireless laptops'
     + ' to help them access the Internet anytime, anywhere. Latest software. are used to enable work on data'
     + ' analysis, web technologies and software designing. The intranet enables close on-line interaction'
     + ' between faculty and students for online interaction.'
  ]

  library = [
    'The libraries at each of the campuses have spacious reading halls, periodical centres, group discussion'
         + ' rooms and online database browsing areas.'
         + ' The University libraries offer a wide range of materials in a variety of formats—from traditional books'
         + ' and serials to films, and multimedia and networked information from around the world. Highly skilled staff'
         + ' assists students to use the local collections and find information on specific topics.'
  ]

  cafe = [
    '"Variety is the spice of life" SBIHE canteen menu refliects just that. Our canteen is not only a place to'
    + ' fill but also a venue where ideas & views converge and give rise to innovative ventures. It offers vegetarian'
    + ' delicacies and caters to the diverse palates and for the health freaks, we have a dedicated juice counter providing'
    + ' fresh fruit juice. Delicious varieties are prepared according to strict standard of hygiene.'
  ]

  medial = [
    'A first and centre with major facilities is available. Qualified doctors are availables on call as and when'
    + ' required. Besiders, attending to the medical needs of the students, staff and others in the campus is our top priority.'
    
  ]

  transport = [
    'A fleet of buses are provided by the institute as transport facility to pick and drop day scholars from Shamli City'
    + ' and nearby as.'
  ]

  Teach = [
    'The teaching methods require constant interaction between the B.Ed. students and the faculty members. The'
    + ' faculty students interaction are made stronger and result oriented through well planned lectures, practicle'
    + ' sessions, case studies, group discussions project work, field expreriences, assignments, seminars etc.'
  ]

  Hall = [
    'A well equipped air conditioned conference hall with State of the art Audio - Video facility, LCD projectors and'
    + ' and a seating capacity in a "U" shaped formation makes it an ideal centre where necessity blended with'
    + ' luxury co-exist.'
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
