import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';
import { HttpMasterService } from 'src/app/core/services/http/http-master.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})

export class CarouselComponent implements OnInit {
  notice = {
    notice1: '',
    notice2: ''
  }
  notices;
  element_data = [];
  images = [
    '../../assets/images/carousel/carousel-3.jpg',
    '../../assets/images/carousel/carousel-1.jpg',
    '../../assets/images/carousel/carousel-2.jpg',
    '../../assets/images/carousel/carousel-4.jpg',
    '../../assets/images/carousel/carousel-5.jpg',
    '../../assets/images/carousel/schl.jpg',
    '../../assets/images/carousel/bba.jpg',
  ];

  Infrstructure = [
    '-- The SBIHE campusserene atmosphere ideally suited for technical education.'
    + ' The infrastructure and facilities available on campus are amongst the very best. '
    + ' It is a wholly self-contained campus comprising of everything that students on campus would ever require.'
  ]
  Location = [
    '- Silver Bells College For Higher Education Kairana Panipat Road, Shamli located Uttar Pradesh is one of the '
    + ' popular colleges in India. The College has been rated by 39 people on iCBSE.'
  ]
  Bcatext = [
    ' Bachelor of Computer Applications is a full time three years course offered by Chaudhary Charan Singh'
    +' University, Meerut It is an undergraduate degree course in computer applications. With the rapid growth'
    + ' of IT industry in India, the demand of computer professional is increasing day by day.',
    'The demand of IT professionals is increasing not only in India but also in abroad. After the completion '
    + ' of the course, the student can find a job in reputed IT companies like .IBM, Oracle, Infosys, and Google. The student can work as a system engineer, junior programmer, web developer or as a system administrator.'
  ]
  Bcomtext = [
    'Full form of B.COM is Bachelor of Commerce. B.COM is an extremely popular undergraduate course that students puruse'
    + ' right after their class 12th. Here are some important facts to konw about Bachelor or Arts: “A Bachelor of '
    + ' Commerce, abbreviated as B.Com is an undergraduate degree in commerce and related subjects.""',
    ' A career in commerce offers immense growth as demand for professionals is always on a high. Job opportunities'
    + ' exists right after the programme. Jobs for B.Com graduates exists in India as well as in abroad. After completion'
    + ' of B.Com, you can apply in any organization for the post of accountant..'
  ]
  Bbatext = [
    ' Bachelor of Business Administration or BBA is one of the most popular bachelor degree programmes after class'
    + ' XII. The BBA course is the gateway to numerous job opportunities in a plethora of sectors like Marketing,'
    + ' Education, Finance, Sales, and Government to name just a few.',
    ' On completion of BBA, aspirants generally opt for MBA. However, candidates can also choose to explore job'
    + ' opportunities in various fields like banking, urban infrastructure & real estate management, business'
    + ' consultancies, advertising, manufacturing and in Government sectors to name a few.'
  ]

  BEDTest = [
    'Considered to be the noblest profession, Teaching has always been one of the most popular career choices '
    +' among students. However, to become a teacher at the pre-nursery, nursery, primary, secondary and senior'
    +' secondary levels in schools or to become a professor at the college/university level, aspirants need to'
    +' possess the right qualifications. ',
    'On completing Bachelor of Education, aspirants can become teachers at the primary, secondary and senior '
    +'secondary levels in schools. In order to be hired as an Assistant Professor or Professor at the '
    + 'college/university level, candidates need to appear for Teacher Eligibility Tests like CTET, APTET,'
    +' TSTET or UGC NET. Candidates with a B.Ed degree can apply for jobs at all government and private schools in India.'
  ]

  constructor(config: NgbCarouselConfig, public httpMaster: HttpMasterService
  ) {
    config.interval = 2500;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  ngOnInit() {
    // this.getNotice();
  }

  async getNotice() {
    let payload = '';
    let url = 'http://localhost:3000/test/silver/notice';
    let result;
    try {
      result = await this.httpMaster.postRequest(url, payload);
      console.log('Notice: ' + JSON.stringify(result.data.rows[0].notice));
      
      this.notices = result.data.rows;  
      
      // alert('notice:: ' + JSON.stringify(this.notices));

      this.notice.notice1 = result.data.rows[0].notice;
      this.notice.notice2 = result.data.rows[1].notice;
    } catch (error) {
      // alert('getNoticeL: ' + error);
      error('something went wrong')
    }
  }

}
