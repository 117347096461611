import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/core/services/contact/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  contactFormData: any = {
    FullName: '',
    Contactno: '',
    Email: '',
    Address: '',
    Description: ''
  };
  Getconctactus: any = {
    college_address: '',
    college_email: '',
    map: '',
    telephone_no: ''
  }
  result: any;
  maplink = [];

  image = [
    '../../assets/images/carousel/carousel-3.jpg',
    '../../assets/images/gall (29).jpg',
  ]
  
  constructor( public contactusService: ContactService,
    private snackBar: MatSnackBar,
    ) { }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

 
  async contactForm() {

    try {
      await this.contactusService.postContactForm(this.contactFormData);
      this.openSnackBar('Successfully ','Applied')
    } catch (error) {
      alert('contactForm: ' + error);
    }
  }

  ngOnInit(): void {

  }


}
