import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {

  images = [
    '../../../assets/images/credit/aman.jpg',
    '../../../assets/images/credit/amit.jpeg',
    '../../../assets/images/credit/abhi.jpeg',
    '../../../assets/images/credit/ankush.jpeg',
    '../../../assets/images/credit/junaid.jpeg'
  ]

  constructor() { }

  ngOnInit() {
  }

}
