<div class="modal-content">

  <div class="container">
    <h2 class="font_style">Fee Structure of all Courses</h2>

    <mat-tab-group (selectedTabChange)="getData()">
      <mat-tab label="BCA">

        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Fee Type </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
        </table>

      </mat-tab>
      <mat-tab label="BBA">


        <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Fee Type </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>


      </mat-tab>
      <mat-tab label="BCom">


        <table mat-table [dataSource]="dataSource4" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Fee Type </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
        </table>

      </mat-tab>

   
      <mat-tab label="BED">

        <table mat-table [dataSource]="dataSource7" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Fee Type </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns7"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns7;"></tr>
        </table>

      </mat-tab>
    </mat-tab-group>
  </div>
</div>