import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatGridListModule} from '@angular/material/grid-list';
import { HttpClientModule } from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';



// Custom Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './pages/login/login.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { HomeComponent } from './pages/home/home.component';
import { AccountsComponent } from './pages/credit/accounts.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PagesComponent } from './pages/pages.component';

import { from } from 'rxjs';
import { BcaComponent } from './components/course/bca/bca.component';
import { BbaComponent } from './components/course/bba/bba.component';
import { BcomComponent } from './components/course/bcom/bcom.component';
import { BedComponent } from './components/course/bed/bed.component';
import { AdmissionComponent } from './components/acadmic/admission/admission.component';
import { FeeComponent } from './components/acadmic/fee/fee.component';
import { SyllabusComponent } from './components/acadmic/syllabus/syllabus.component';
import { InfrastructureComponent } from './components/campus/infrastructure/infrastructure.component';
import { GalleryComponent } from './components/campus/gallery/gallery.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { DocumentComponent } from './components/document/document.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent, // custom Header Component
    FooterComponent, // custom Footer Component
    LoginComponent, // custom login component
    CarouselComponent, // custom Carousel Component for home page
    ContactusComponent,
    HomeComponent,
    AccountsComponent,
    AboutusComponent,
    PagesComponent,
    BcaComponent,
    BbaComponent,
    BcomComponent,
    BedComponent,
    AdmissionComponent,
    FeeComponent,
    SyllabusComponent,
    InfrastructureComponent,
    GalleryComponent,
    FeedbackComponent,
    DocumentComponent,
  ],

  imports: [
    MatTableModule,
    HttpClientModule,
    MatGridListModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatExpansionModule,
    MatMenuModule,
    MatIconModule,
    NgbModule, // Ng BootStarp module
    MatSidenavModule,
    MatNativeDateModule,
    RouterModule,
    CommonModule,
    MatListModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatSlideToggleModule,
    AppRoutingModule,
    MatSnackBarModule
  ],

  exports: [
  ],

  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
