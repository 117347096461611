import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { UtilityService } from '../core/services/utils/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {


  // @ViewChild('clickMe', opts) clickMe: any;
  @ViewChild(MatMenuTrigger, {read: true, static: true}) trigger: MatMenuTrigger;


  constructor(public utilityService: UtilityService) {}

  ngOnInit() {
    // this.todaydate = this.dateService.todayDate();
  }

  // method to get current/todays date from DateService
  getDate() {
    return this.utilityService.getCurrentDate();
  }

  clickOnHover() {
    // this.clickMe._elementRef.nativeElement.click();
    // this.trigger.openMenu();

  }

}
