import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModelMaster } from '../../model/model-master';
import { CryptoService } from '../crypto/crypto.service';


@Injectable({providedIn: 'root'})
export class LoginService {

  modelMaster = new ModelMaster();
  constructor(public crypto: CryptoService){}

  login(userName, password) {
    // loginRequest: LoginRequest;
    // loginResponse: LoginResponse;
    let payload = '';
    let loginModel = this.modelMaster.getLoginModel();
    loginModel.userName = userName;
    loginModel.password = password;

    payload = this.crypto.encrypt(JSON.stringify(loginModel));

    alert('loginModel: ' + JSON.stringify(loginModel) + ' : Encrypt: ' + payload);

    return payload;
  }

}
