import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpMasterService } from 'src/app/core/services/http/http-master.service';

export interface BCAElement {
  name: string;
  position: number;
  weight: number;
}
export interface BBAElement {
  name: string;
  position: number;
  weight: number;
}

export interface BCOMElement {
  name: string;
  position: number;
  weight: number;
}

export interface BEDElement {
  name: string;
  position: number;
  weight: number;
}


let ELEMENT_DATA_BCA: BCAElement[] = [
  { position: 1, name: 'Fee/Year', weight: 22500 },
  // { position: 2, name: 'Total Fee', weight: 67500 },
];


const ELEMENT_DATA_BBA: BBAElement[] = [
  { position: 1, name: 'Fee/Year', weight: 22500 },
  // { position: 2, name: 'Total Fee', weight: 67500 },
];

const ELEMENT_DATA_BCOM: BCOMElement[] = [
  { position: 1, name: 'Fee/Year', weight: 8000 },
  // { position: 2, name: 'Total Fee', weight: 24000 }
];

const ELEMENT_DATA_BED: BEDElement[] = [
  { position: 1, name: 'Fee/Year', weight: 51250 },
  { position: 2, name: 'Second/Year', weight: 30000 },
  { position: 3, name: 'Total Fee', weight: 81250 },
];

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.css']
})
export class FeeComponent implements OnInit {

  Data: any;
  registrationbca = '';
  tutionbca = '';
  booksbca = '';
  buildingbca = '';
  totalbca = '';

  registrationbba = '';
  tutionbba = '';
  booksbba = '';
  buildingbba = '';
  totalbba = '';

  registrationbcom = '';
  tutionbcom = '';
  booksbcom = '';
  buildingbcom = '';
  totalbcom = '';

  registrationbed = '';
  tutionbed = '';
  booksbed = '';
  buildingbed = '';
  totalbed = '';
  constructor(
    public httpMaster: HttpMasterService
  ) { this.registrationbca; }

  displayedColumns1: string[] = ['position', 'name', 'weight'];
  dataSource1 = ELEMENT_DATA_BCA;

  displayedColumns2: string[] = ['position', 'name', 'weight'];
  dataSource2 = ELEMENT_DATA_BBA;

  displayedColumns4: string[] = ['position', 'name', 'weight'];
  dataSource4 = ELEMENT_DATA_BCOM;

  displayedColumns7: string[] = ['position', 'name', 'weight'];
  dataSource7 = ELEMENT_DATA_BED;


  ngOnInit() {
    // this.getDataFromServer();
    //this.getData();
  }

  async getDataFromServer() {
    let payload = '';
    let url = 'http://localhost:3000/test/silver/feestructure';
    this.Data = await this.httpMaster.postRequest(url, payload);
    if (this.Data.status = 1) {
      this.getData();
    }
  }


  getData() {

    try {

      // BCA START
      this.registrationbca = JSON.stringify(this.Data.data.rows[0].registration_fee);
      ELEMENT_DATA_BCA[0].weight = JSON.parse(this.registrationbca);


      this.tutionbca = JSON.stringify(this.Data.data.rows[0].tution_fee_per_year);
      ELEMENT_DATA_BCA[1].weight = JSON.parse(this.tutionbca);

      this.booksbca = JSON.stringify(this.Data.data.rows[0].books_and_labs_per_year);
      ELEMENT_DATA_BCA[2].weight = JSON.parse(this.booksbca);


      this.buildingbca = JSON.stringify(this.Data.data.rows[0].building_fee);
      ELEMENT_DATA_BCA[3].weight = JSON.parse(this.buildingbca);


      this.totalbca = JSON.parse(this.registrationbca) + JSON.parse(this.tutionbca) + JSON.parse(this.booksbca) + JSON.parse(this.buildingbca);
      ELEMENT_DATA_BCA[4].weight = JSON.parse(this.totalbca);
      // BCA END


      //BBA START
      this.registrationbba = JSON.stringify(this.Data.data.rows[1].registration_fee);
      ELEMENT_DATA_BBA[0].weight = JSON.parse(this.registrationbba);


      this.tutionbba = JSON.stringify(this.Data.data.rows[1].tution_fee_per_year);
      ELEMENT_DATA_BBA[1].weight = JSON.parse(this.tutionbba);

      this.booksbba = JSON.stringify(this.Data.data.rows[1].books_and_labs_per_year);
      ELEMENT_DATA_BBA[2].weight = JSON.parse(this.booksbba);


      this.buildingbba = JSON.stringify(this.Data.data.rows[1].building_fee);
      ELEMENT_DATA_BBA[3].weight = JSON.parse(this.buildingbba);


      this.totalbba = JSON.parse(this.registrationbba) + JSON.parse(this.tutionbba) + JSON.parse(this.booksbba) + JSON.parse(this.buildingbba);
      ELEMENT_DATA_BBA[4].weight = JSON.parse(this.totalbba);
      // BBA END


      // BCOM START
      this.registrationbcom = JSON.stringify(this.Data.data.rows[2].registration_fee);
      ELEMENT_DATA_BCOM[0].weight = JSON.parse(this.registrationbcom);


      this.tutionbcom = JSON.stringify(this.Data.data.rows[2].tution_fee_per_year);
      ELEMENT_DATA_BCOM[1].weight = JSON.parse(this.tutionbcom);

      this.booksbcom = JSON.stringify(this.Data.data.rows[2].books_and_labs_per_year);
      ELEMENT_DATA_BCOM[2].weight = JSON.parse(this.booksbcom);


      this.buildingbcom = JSON.stringify(this.Data.data.rows[2].building_fee);
      ELEMENT_DATA_BCOM[3].weight = JSON.parse(this.buildingbcom);


      this.totalbcom = JSON.parse(this.registrationbcom) + JSON.parse(this.tutionbcom) + JSON.parse(this.booksbcom) + JSON.parse(this.buildingbcom);
      ELEMENT_DATA_BCOM[4].weight = JSON.parse(this.totalbcom);
      // BCOM END

      // BED START
      this.registrationbed = JSON.stringify(this.Data.data.rows[3].registration_fee);
      ELEMENT_DATA_BED[0].weight = JSON.parse(this.registrationbed);


      this.tutionbed = JSON.stringify(this.Data.data.rows[3].tution_fee_per_year);
      ELEMENT_DATA_BED[1].weight = JSON.parse(this.tutionbed);

      this.booksbed = JSON.stringify(this.Data.data.rows[3].books_and_labs_per_year);
      ELEMENT_DATA_BED[2].weight = JSON.parse(this.booksbed);


      this.buildingbed = JSON.stringify(this.Data.data.rows[3].building_fee);
      ELEMENT_DATA_BED[3].weight = JSON.parse(this.buildingbed);


      this.totalbed = JSON.parse(this.registrationbed) + JSON.parse(this.tutionbed) + JSON.parse(this.booksbed) + JSON.parse(this.buildingbed);
      ELEMENT_DATA_BED[4].weight = JSON.parse(this.totalbed);
      // BED END

    } catch (error) {
      error('Something went wrong')
    }
  }


}


