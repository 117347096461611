<!-- <div class="modal-content">
    Home >> Feadback
    <mat-toolbar style="background-color: white;">
        <span style="font-size: 28px;">Feadback Forms</span>
    </mat-toolbar>
    <mat-divider style="border: 1px solid blue;"></mat-divider>
    <br>
    <mat-toolbar-row class="toolbar-row">
        <div class="toolbar-row">
            <button mat-button [matMenuTriggerFor]="Students">From Students</button>
            <button mat-button [matMenuTriggerFor]="Parents">From Parents</button>
            <button mat-button [matMenuTriggerFor]="Employer">From Employer</button>
            <button mat-button [matMenuTriggerFor]="Teachers">From Teachers </button>
            <button mat-button [matMenuTriggerFor]="Alummi">From Alummi</button>
        </div>

        <mat-menu #Students="matMenu">
            <button mat-menu-item routerLink='/clg' routerLinkActive="active">About clg</button>
            <button mat-menu-item routerLink='/welcome' routerLinkActive="active">About Teaching</button>
            <button mat-menu-item routerLink='/welcome' routerLinkActive="active">On Curse on Cyber Security</button>
            <button mat-menu-item routerLink='/welcome' routerLinkActive="active">On Curse on Human Rights</button>
            <button mat-menu-item routerLink='/welcome' routerLinkActive="active">On Skill development Course</button>
            <button mat-menu-item routerLink='/welcome' routerLinkActive="active">About Teachers</button>
        </mat-menu>

        <mat-menu #Parents="matMenu"></mat-menu>

        <mat-menu #Employer="matMenu"></mat-menu>

        <mat-menu #Teachers="matMenu"></mat-menu>

        <mat-menu #Alummi="matMenu"></mat-menu>
    </mat-toolbar-row>
    <br>
</div>


<div class="modal-content">
    <mat-toolbar>
        <span style="font-size: 48px;">Comming Soon</span>
    </mat-toolbar>
</div>

<pre>




    
</pre>
<div class="modal-content">
    <mat-toolbar>
        <span style="font-size: 26px;">Review</span>
    </mat-toolbar>
</div>
<mat-card class="modal-content" *ngFor="let review of review">
    <mat-card-header>
        <div mat-card-avatar [style.background-image]="review.URL"
            style="background-size: cover;">
        </div>
        <mat-card-subtitle>Posted on : {{review.Date}} By {{review.Name}} <br>
            Batch of : {{review.Batch}} || {{review.Course}} </mat-card-subtitle>
    </mat-card-header>
    <div> {{review.review}} </div>
</mat-card>
 -->


 <div ><embed src="../../assets/images/NBC.pdf" style="height: 500px;width: 100%;"
    type="application/pdf"></div>