<div class="modal-content">
  <mat-toolbar style="color: blue;background-color: white;">
    <mat-toolbar-row>
      <marquee direction="right"> <span style="font-size: 28px;">
          <div> Syllabus</div>
        </span></marquee>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="container">
  <h1 class="font_style">Subjects of all Courses</h1>


  <!------////////........Bachelor of Computer Applications........./////////----->

  <mat-tab-group>
    <mat-tab label="BCA">

      <!--Section Start-->
      <mat-accordion>

        <!----1.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              First Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>
          <h5>B.C.A-101 --------- Mathematics-1</h5>
          <h5>B.C.A-102 --------- Programming Principle & Alogrithm</h5>
          <h5>B.C.A-103 --------- Computer Fundamental and Office Automation</h5>
          <h5>B.C.A-104 --------- Principal Of Management</h5>
          <h5>B.C.A-105 --------- Business Communication</h5>
          <h5>B.C.A-106 --------- Enviromental Studies</h5>
          <h5>B.C.A-107 --------- Computer laboratory and Partical Work Of Computer Fundamental and Office Alogrithm
          </h5>
          <h5>B.C.A-108 --------- Computer laboratory and Partical Work of Programming Principle & Alogrithm</h5>


        </mat-expansion-panel>


        <!--Expension Panel end-->

        <!---2.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Second Semester and Courses code
            </mat-panel-title>
            <mat-panel-description>
              ////....SUBJECT...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.C.A-201 -------- Mathmatices-II</h5>
          <h5>B.C.A-202 -------- C-Programming</h5>
          <h5>B.C.A-203 -------- Organization Behaviour</h5>
          <h5>B.C.A-204 -------- Digital Electronics Computer Organisation</h5>
          <h5>B.C.A-205 -------- Financial Accounting and Management</h5>
          <h5>B.C.A-206 -------- Computer laboratory and Pratical Work of C</h5>

        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!---3.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Third Semester and Courses
            </mat-panel-title>
            <mat-panel-description>
              ////....SUBJECT...////
            </mat-panel-description>
          </mat-expansion-panel-header>
          <h5>B.C.A-301 ------- Object Oriented Programming Using C++</h5>
          <h5>B.C.A-302 ------- Data Structure Using C & C++</h5>
          <h5>B.C.A-303 ------- Computer Architecture & Assembly Language</h5>
          <h5>B.C.A-304 ------- Business Economics</h5>
          <h5>B.C.A-305 ------- Elements Of Statics</h5>
          <h5>B.C.A-306 ------- Computer laboratory and Pratical work of OOPS</h5>
          <h5>B.C.A-307 ------- Computer laboratory and Partical Work of DS</h5>

        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!---4.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Fourth Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.C.A-401 ------- Computer Graphics & Mathmedia Application </h5>
          <h5>B.C.A-402 ------- Operating System</h5>
          <h5>B.C.A-403 ------- Software Engineering</h5>
          <h5>B.C.A-404 ------- Optimization Techniques</h5>
          <h5>B.C.A-405 ------- Partical Based on Subject Code -401 </h5>
          <h5>B.C.A-406 ------- Mathematices-III</h5>


        </mat-expansion-panel>
        <!--Expension Panel end-->


        <!---5.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Fifth Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.C.A-501 ------- Introduction to DBMS </h5>
          <h5>B.C.A-502 -------Java Programming and Dynamic Webpage Design </h5>
          <h5>B.C.A-503 ------- Computer Network</h5>
          <h5>B.C.A-504 ------- Numerical Methods</h5>
          <h5>B.C.A-505 ------- Computer Laboratory and Practical Work of DBMS </h5>
          <h5>B.C.A-506 ------- Computer Laboratory and Practical Work of Java Programming & Dynamic Webpage Design</h5>
          <h5>B.C.A-507 ------- Viva-Voice on Summer Training</h5>
          <h5>B.C.A-508 ------- Minor Project</h5>


        </mat-expansion-panel>
        <!--Expension Panel Start-->

        <!---6.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sixth Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.C.A-601 ------- Computer Network Security </h5>
          <h5>B.C.A-602 -------Information System: Analysis Design & Implementation </h5>
          <h5>B.C.A-603 ------- E-Commerce</h5>
          <h5>B.C.A-604 ------- Knowledge Management</h5>
          <h5>B.C.A-605 ------- Major Project</h5>
          <h5>B.C.A-606 ------- Presentation/Seminar based on Major Project</h5>

        </mat-expansion-panel>
        <!--Expension Panel Start-->


      </mat-accordion>
      <!--Section End-->



      <!----////////.......Bachelor of Business Administration......////------->

    </mat-tab>
    <mat-tab label="BBA">


      <!--Section Start-->
      <mat-accordion>

        <!---1.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              First Semester and Courses
            </mat-panel-title>
            <mat-panel-description>
              ////....SUBJECT...////
            </mat-panel-description>
          </mat-expansion-panel-header>
          <h5>B.B.A-101 ------- Business Organisation</h5>
          <h5>B.B.A-102 ------- Business Mathematics</h5>
          <h5>B.B.A-103 ------- Principal of Economics</h5>
          <h5>B.B.A-104 ------- Book Keeping and Basic Accounting</h5>
          <h5>B.B.A-105 ------- Business Laws</h5>
          <h5>B.B.A-106 ------- Fundamentals of Management</h5>
          <h5>B.B.A-107 ------- Business Ethics</h5>

        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!----2.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Two Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.B.A-201 ------- Business Enviroment</h5>
          <h5>B.B.A-202 ------- Business Communication</h5>
          <h5>B.B.A-203 ------- Indian Economy</h5>
          <h5>B.B.A-204 ------- Principles of Accounting</h5>
          <h5>B.B.A-205 ------- Organisation Behaviour</h5>
          <h5>B.B.A-206 ------- Business Statistics</h5>


        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!---3.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Third Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.B.A-301 ------- Advertising Management</h5>
          <h5>B.B.A-302 ------- Indian Banking System</h5>
          <h5>B.B.A-303 ------- Human Resource Management</h5>
          <h5>B.B.A-304 ------- Marketing Management</h5>
          <h5>B.B.A-305 ------- Company Accounts</h5>
          <h5>B.B.A-306 ------- Company Law</h5>


        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!---4.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Fourth Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.B.A-401 ------- Comsumer Behaviour</h5>
          <h5>B.B.A-402 ------- Financial Management</h5>
          <h5>B.B.A-403 ------- Production Management</h5>
          <h5>B.B.A-404 ------- Sales Management</h5>
          <h5>B.B.A-405 ------- Research Methodology</h5>
          <h5>B.B.A-406 ------- Operations Research</h5>


        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!----5.Expension Panel Start-->

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Fivth Semester and Courses Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h5>B.B.A-501 ------- Managerial Economics</h5>
          <h5>B.B.A-502 ------- Enterpreneurship & Small Business Management</h5>
          <h5>B.B.A-503 ------- Income Tax Law and Account</h5>
          <h5>B.B.A-504 ------- Cost and Management Accounting</h5>
          <h5>B.B.A-505 ------- Industrial Law </h5>
          <h5>B.B.A-506 ------- Computer Fundamental</h5>


        </mat-expansion-panel>
        <!--Expension Panel end-->

        <!--=6.Expension Panel Start-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sixth Semester and Course Code
            </mat-panel-title>
            <mat-panel-description>
              ////...Subject...////
            </mat-panel-description>
          </mat-expansion-panel-header>
          <h5>B.B.A-601 --------- International Trade </h5>
          <h5>B.B.A-602 --------- Strategic Management & Business Policy</h5>
          <h5>B.B.A-603 --------- Vat & Service Tax </h5>
          <h5>B.B.A-604 --------- Management Information System </h5>
          <h5>B.B.A-605 --------- Auditing </h5>
          <h5>B.B.A-606 --------- Fundamental of E-Commerce </h5>

        </mat-expansion-panel>
        <!--Expension Panel Start-->

      </mat-accordion>
      <!--Section End-->

      <!------/////////..........Bachelor of Commerce..........////////----->


    </mat-tab>
    <mat-tab label="BCOM">

      <!---1.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            First Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.Com-101 -------- Business Comminication</h5>
        <h5>B.Com-102 -------- Business Statistics</h5>
        <h5>B.Com-103 -------- Financial Accounting</h5>
        <h5>B.Com-104 -------- Business Regulatory Framework</h5>
        <h5>B.Com-105 -------- Business Economics</h5>
        <h5>B.Com-106 -------- Business Environment</h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->


      <!---2.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Second Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.Com-201 -------- Company Law </h5>
        <h5>B.Com-202 -------- Cost Accounting </h5>
        <h5>B.Com-203 -------- Principles of Business Management </h5>
        <h5>B.Com-204 -------- Income Tax</h5>
        <h5>B.Com-205 -------- Fundamentals of Entrepreneurship</h5>
        <h5>B.Com-206 -------- Public Finance</h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->


      <!---3.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Third Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.Com-301 -------- Corporate Accounting </h5>
        <h5>B.Com-302 -------- Auditing</h5>
        <h5>B.Com-303 -------- Principles Of Marketing</h5>
        <h5>B.Com-304 -------- Economic Laws</h5>
        <h5>B.Com-305 -------- E-Commerce</h5>
        <h5>B.Com-306 -------- Management Accounting</h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->

    </mat-tab>

    <!------///////..........Bachelor of Art..........////////////----->


    <mat-tab label="B.A">



      <!---1.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            First Year and courses
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.A-1 -------- Hindi </h5>
        <h5>B.A-2 -------- English </h5>
        <h5>B.A-3 -------- Soicalogy </h5>
        <h5>B.A-4 -------- Policital Science </h5>
        <h5>B.A-5 -------- Sanskrit </h5>
        <h5>B.A-6 -------- Drawing </h5>
        <h5>B.A-7 -------- Home Science </h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->


      <!---2.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Second Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.A-1 -------- Hindi </h5>
        <h5>B.A-2 -------- English </h5>
        <h5>B.A-3 -------- Soicalogy </h5>
        <h5>B.A-4 -------- Policital Science </h5>
        <h5>B.A-5 -------- Sanskrit </h5>
        <h5>B.A-6 -------- Drawing </h5>
        <h5>B.A-7 -------- Home Science </h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->



      <!---3.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Third Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.A-1 -------- Hindi </h5>
        <h5>B.A-2 -------- English </h5>
        <h5>B.A-3 -------- Soicalogy </h5>
        <h5>B.A-4 -------- Policital Science </h5>
        <h5>B.A-5 -------- Sanskrit </h5>
        <h5>B.A-6 -------- Drawing </h5>
        <h5>B.A-7 -------- Home Science </h5>
      </mat-expansion-panel>
      <!--Expension Panel end-->

      <!------///////.......... Bachelor of Education..........////////////----->


    </mat-tab>
    <mat-tab label="B.Ed">


      <!---.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            First Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.Ed-CC 1: -------- Contemparory India and Education </h5>
        <h5>B.Ed-CC 2: -------- Philosophical and Sociological Perspectives of Education</h5>
        <h5>B.Ed-CC 3: -------- Growing up as a letter</h5>
        <h5>B.Ed-CC 4: -------- Teacher,Teaching and Technology </h5>
        <h5>B.Ed-PC 1: -------- Pedagogy of a School Subject-I</h5>
        <h5>B.Ed-PC 2: -------- Pedagogy of a School Subject-II</h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->


      <!---2.Expension Panel Start-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Second Year and Courses code
          </mat-panel-title>
          <mat-panel-description>
            ////....SUBJECT...////
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h5>B.Ed-CC 5: --------Creative an Inclusive School</h5>
        <h5>B.Ed-CC 6: -------- Gender, School and Society </h5>
        <h5>B.Ed-CC 7: -------- Knowledge Language and Curriculum</h5>
        <h5>B.Ed-PC 3: -------- Assessment For Learning</h5><br>
        <h5>B.Ed-PC 4: -------- ((Optional Courses any one of the following))</h5>
        <h5>B.Ed---(i) -------- Educational Administration and Management</h5>
        <h5>B.Ed---(ii) -------- Guidance and Conselling</h5>
        <h5>B.Ed---(iii) -------- Environment Education</h5>
        <h5>B.Ed---(iv) -------- Computer Education</h5>
        <h5>B.Ed---(v) -------- Health, Physical Education and Yog</h5>
        <h5>B.Ed---(vi) -------- Life Style Management</h5>

      </mat-expansion-panel>
      <!--Expension Panel end-->


    </mat-tab>
  </mat-tab-group>
</div>
<br>

<div class="modal-content">
  <mat-toolbar style="color: blue;background-color: white;">
    <mat-toolbar-row>
      <marquee direction="right"> <span style="font-size: 28px;">
          <div> GET PDF</div>
        </span></marquee>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="modal-contents">
  <!-- <mat-grid-list cols="2" rowHeight="6:1" style="border: 2px solid black;height: auto;">
    <mat-grid-tile style="color: black;">Complete syllabus for BCA</mat-grid-tile>
    <mat-grid-tile><button><a href="https://www.moderncollege.org/Syllabus/BCA.pdf">click here</a></button>
    </mat-grid-tile>
    <mat-grid-tile style="color: black;">Complete syllabus for BBA</mat-grid-tile>
    <mat-grid-tile><button><a href="https://www.ccsuniversity.ac.in/web/Syllabus2/bba.pdf">click here</a></button>
    </mat-grid-tile>
    <mat-grid-tile style="color: black;"> Complete syllabus for B.Com</mat-grid-tile>
    <mat-grid-tile><button><a href="http://www.ccsuniversity.ac.in/web/Syllabus/bcom-18.pdf">click here</a></button>
    </mat-grid-tile>
    <mat-grid-tile style="color: black;">Complete syllabus for B.ED</mat-grid-tile>
    <mat-grid-tile><button><a href="http://www.ccsuniversity.ac.in/ccsu/Syllabus/syllabus-BEd-2019-21.pdf">click
          here</a></button></mat-grid-tile>
  </mat-grid-list> -->

  <mat-toolbar-row style="height: 100%;">

    <div class="container">
      <div class="row">
        <div class="col-sm-6"  style="color: black;">Complete syllabus for BCA</div>
        <div class="col-sm-6"><button><a href="https://www.moderncollege.org/Syllabus/BCA.pdf">click here</a></button></div> <br><br>
        <div class="col-sm-6"style="color: black;">Complete syllabus for BBA</div>
        <div class="col-sm-6"><button><a href="https://www.ccsuniversity.ac.in/web/Syllabus2/bba.pdf">click here</a></button></div><br><br>
         <div class="col-sm-6"  style="color: black;">Complete syllabus for B.COM</div>
        <div class="col-sm-6"><button><a href="http://www.ccsuniversity.ac.in/web/Syllabus/bcom-18.pdf">click here</a></button></div><br><br>
        <div class="col-sm-6"style="color: black;">Complete syllabus for B.ED</div>
        <div class="col-sm-6"><button><a href="http://www.ccsuniversity.ac.in/ccsu/Syllabus/syllabus-BEd-2019-21.pdf">click here</a></button></div><br><br>
      </div>
    </div>

  </mat-toolbar-row>
</div>