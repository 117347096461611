import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AccountsComponent } from './pages/credit/accounts.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { BcaComponent } from './components/course/bca/bca.component';
import { BbaComponent } from './components/course/bba/bba.component';
import { BcomComponent } from './components/course/bcom/bcom.component';
import { BedComponent } from './components/course/bed/bed.component';
import { AdmissionComponent } from './components/acadmic/admission/admission.component';
import { FeeComponent } from './components/acadmic/fee/fee.component';
import { SyllabusComponent } from './components/acadmic/syllabus/syllabus.component';
import { InfrastructureComponent } from './components/campus/infrastructure/infrastructure.component';
import { GalleryComponent } from './components/campus/gallery/gallery.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { DocumentComponent } from './components/document/document.component';

const AppRoutes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'Home' } },
  { path: 'home', component: HomeComponent, data: { title: 'Home' } },
  { path: 'aboutus', component: AboutusComponent, data: { title: 'About' } },
  { path: 'accounts', component: AccountsComponent, data: { title: 'Accounts' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'bca', component: BcaComponent, data: { title: 'bca' } },
  { path: 'bba', component: BbaComponent, data: { title: 'bba' } },
  { path: 'bcom', component: BcomComponent, data: { title: 'bcom' } },
  { path: 'bed', component: BedComponent, data: { title: 'bed' } },
  { path: 'admission', component: AdmissionComponent, data: { title: 'admission' } },
  { path: 'fee', component: FeeComponent, data: { title: 'fee' } },
  { path: 'syllabus', component: SyllabusComponent, data: { title: 'syllabus' } },
  { path: 'infr', component: InfrastructureComponent, data: { title: 'infrastructure' } },
  { path: 'gallery', component: GalleryComponent, data: { title: 'gallery' } },
  { path: 'feed', component: FeedbackComponent, data: { title: 'feedback' } },
  { path: 'contact', component: ContactusComponent, data: { title: 'contact' } },
  { path: 'credit', component: AccountsComponent, data: { title: 'credit' } },
  { path: 'document', component: DocumentComponent, data: { title: 'document' } },
  
];

@NgModule ({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
