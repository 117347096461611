import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  review = [
    {
      Name: 'aman Jangid',
      Course: 'BCA',
      Batch: '2017 - 2020',
      Date: '25-oct-2019',
      URL: 'url(../../../assets/images/credit/aman.jpg)',
      review: 'The campus is well equipped with necessary infrastructure like smart boards, AC, wifi access '
              +' and many more. The library is really good with sufficient study material. The sports area is'
              + ' really great. I love to spend time at the badminton court. The campus area is very very tidy,'
              +' clean and green.'
    },
    {
      Name: 'Amit chauchan',
      Course: 'BCA',
      Batch: '2017 - 2020',
      Date: '25-oct-2019',
      URL: 'url(../../../assets/images/credit/amit.jpeg)',
      review: 'I am a student pursuing BTech in Data Science and Artificial intelligence stream. The quality of'
              + ' teaching is absolutely great with updated curriculum. If your are passionate enough and hard'
              + ' working then this academics can make you job-ready.'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
