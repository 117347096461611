<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
    <mat-toolbar style="background-color: white;">
      <mat-toolbar-row>
        <marquee direction="right" style="font-size: 38px;font-family: serif;">
        Documents</marquee>
      </mat-toolbar-row>
    </mat-toolbar>  
  </div>
    <div class="col-sm-4"></div>
  </div>
</div>
  
<div class="modal-contents">
  <mat-toolbar-row style="height: 100%;">

    <div class="container">
      <div class="row" style="border-style: outset;">
        <div class="col-sm-6"  style="color: black;">NBC Document</div>
        <div class="col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button> <a href="{{document[0]}}">click here</a></button></div> <br><br>       
        <div class="col-sm-6"style="color: black;">Society Members Document</div>
        <div class="col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button><a href="{{document[1]}}">click here</a></button></div> <br><br>
        <div class="col-sm-6"  style="color: black;">Land Use Certificate Document</div>
        <div class="col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button><a href="{{document[2]}}">click here</a></button></div> <br><br>
        <div class="col-sm-6"style="color: black;">Land Document</div>
        <div class="col-sm-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button><a href="{{document[3]}}">click here</a></button></div> <br><br>
      </div>
    </div>

  </mat-toolbar-row>
</div>