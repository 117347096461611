import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  images = [
    '../../assets/images/gall (23).jpg',
    '../../assets/images/gall (24).jpg',
    '../../assets/images/gall (25).jpg',
    '../../assets/images/gall (2).jpg',
    '../../assets/images/gall (3).jpg',

    '../../assets/images/gall (28).jpg',
    '../../assets/images/gall (29).jpg',
    '../../assets/images/gall (27).jpg',

    '../../assets/images/gall (4).jpg',
    '../../assets/images/gall (12).jpg',
    '../../assets/images/gall (11).jpg',

    '../../assets/images/gall (9).jpg',
    '../../assets/images/gall (20).jpg',
    '../../assets/images/gall (7).jpg',

    '../../assets/images/gall (6).jpg',
    '../../assets/images/gall (5).jpg',
    '../../assets/images/gall (26).jpg',

    '../../assets/images/gall (14).jpg',
    '../../assets/images/gall (15).jpg',
    '../../assets/images/gall (22).jpg',

    '../../assets/images/gall (17).jpg',
    '../../assets/images/gall (21).jpg',
    '../../assets/images/gall (19).jpg',
    '../../assets/images/gall (31).jpg',
    '../../assets/images/gall (32).jpg',
    '../../assets/images/gall (33).jpg',
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
