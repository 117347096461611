<div class="modal-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-1"></div>

            <div class="col-sm-12 col-md-5">
                <mat-card>
                    <mat-card-header>
                        <div mat-card-avatar
                            [style.background-image]="'url(../../../assets/images/carousel/carousel-3.jpg)'"
                            style="background-size: cover;">
                        </div>
                        <mat-card-title>SILVER BELLS INSTITUTE FOR HIGHER EDUCATION SHAMLI</mat-card-title>
                        <mat-card-subtitle>
                            <b>CONTACT DETAILS<br></b>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-title> <img style="height:250px;width: 100%;" [src]="image[0]" class="img-carousel">
                    </mat-card-title><br>
                    <span style="color: brown"><b>Location : </b></span>Kairana Panipat Road(Shamli), UTTAR PRADESH <br>
                    <span style="color: brown"></span>  247776 <br>
                    <span style="color: brown"><b>Email : </b></span>Sbiheshamli@Gmail.com <br>
                    <span style="color: brown"><b>Call : </b></span> 01398-255400, 9760564500
                </mat-card>
            </div>

            <div class="col-sm-12 col-md-5" >
                <mat-card style="height: 507px;">
                    <img style="height:470px;width: 100%;" [src]="image[1]" class="img-carousel">
                </mat-card>
            </div>


        </div>
    </div>
</div>

<div class="modal-content">
    <mat-toolbar>
        <h3 style="font-size: 28px;color: rgb(248, 87, 24);">Location</h3>
    </mat-toolbar>
    <mat-toolbar style="height: 100%;">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3474.6815352065987!2d77.28533951483587!3d29.438102882106918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c2ea74200c9df%3A0xd48bf32845a77511!2sSilver%20Bells%20Institute%20For%20Higher%20Education%20Shamli!5e0!3m2!1sen!2sin!4v1586625305241!5m2!1sen!2sin"
            width="100%" height="380" frameborder="0" style="border:0"></iframe>

    </mat-toolbar>
</div>


