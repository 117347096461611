<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black">
    <mat-toolbar-row>
      <marquee direction="right" style="font-size: 25px;color: blue;">
        B.com(Bachelor of Commerce)</marquee>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Quick Facts </div>
      </span>
    </mat-toolbar-row>
    <mat-toolbar-row style="height: 100%;">

      <div class="container">
        <div class="row">
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13); size: 10vmin;">event_seat</mat-icon>Seats: 120</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">account_balance</mat-icon>Course-Duration: 3 years</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">school</mat-icon>Affilated-By: CCS University<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Meerut)</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">local_library</mat-icon>Medium: Regular</div>
        </div>
      </div>

    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Course Details </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;"> Mode : <b>Full Time</b> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Duration: <b> 3 years</b>
    &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Offered by: <b>Chaudhary Charan Singh University, Meerut</b> <br><br>
    Full form of B.COM is <b>Bachelor of Commerce. </b>B.COM is an extremely popular undergraduate course that students
    puruse right after their class 12th. Here are some important facts to konw about Bachelor or Arts<br>
    “A Bachelor of Commerce, abbreviated as B.Com is an undergraduate degree in commerce and related subjects.
    The course is designed to provide students with a wide range of managerial skills and understanding in streams
    like finance, accounting, taxation and management”.
  </div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Eligibility Criteria </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
  A person, who have passed Higher Secondary/Intermediate (10+2) any other examination recognized as equivalent 
  there to with COMMERCE with 50% more than 50% marks,shall be eligible for the course.
</div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Carrer Oppurtunities </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
    A career in commerce offers immense growth as demand for professionals is always on a high.
    Job opportunities exists right after the programme. Jobs for B.Com graduates exists in India as well as in abroad.
    After completion of <b>B.Com</b>, you can apply in any organization for the post of accountant. Every company
    requires accountant to keep track the profit and loss of their company.
    One must have knowledge in areas like Basic Principles of Accountancy, Cost Accounting and Management Accounting
    to be an accountant. Other than accountant, there are many career options in this field.</div>
</div>



