<div class="modal-content">
    <mat-toolbar style="background-color: white;height: 100%;">
        <span style="font-size: 28px;color: rgb(248, 87, 24);"> Library</span>
    </mat-toolbar><br>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                Of course, the college library offers many benefits beyond the four explored above. Review the complete
                set of responses to discover more about students’ library habits. Then, consider how you might prompt
                your students to make full use of the valuable services and resources offered at your school’s library.
                <br>
                <br>
                Even if your course is fully online, your students can still find ways to use the library’s services,
                whether by accessing databases from home, requesting books through interlibrary loan, chatting with an
                online librarian, or using the library’s website to discover videos, tutorials, and other tutorials that
                will help them conduct their research efficiently and effectively.
            </div>
            
        </div>
    </div>
</div>

<div class="modal-content">

    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[0]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[1]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[2]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-content">
    <mat-toolbar style="background-color: white;height: 100%;">
        <span style="font-size: 28px;color: rgb(248, 87, 24);"> IT Infrastructur</span>
    </mat-toolbar><br>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                The college has high-tech computer graphics studios with more than 360 advanced computers with LAN and
                internet services through wireless connectivity, and LCD projection systems, hardware and software,
                digital cameras, printers, scanners, plotters, photocopying machine and video cameras etc. all are the
                back bone of our infrastructure. Advance graphics lab is equipped with 120 computers with advanced
                configurations, a central control room, audio – visual projection facilities and a central data
                archiving center. As a part of SBIHE’s pursuit to be technologically in synch with the times, the
                following hardware and systems were installed in the college:
                <br>
                <br>
                <b> Policy:</b> To study the needs of the stakeholders of the college periodically, create and enhance
                necessary
                infrastructure facilities and put them to optimum use. The Management Committee meets twice a year to
                plan the Academic and Administrative activities of the college. Besides, as and when the need arises,
                budget for the enhancement of infrastructure is proposed and passed through resolutions in the Governing
                Body Meeting.
            </div>
        </div>
    </div>
</div>

<div class="modal-content">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[3]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[4]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-content">
    <mat-toolbar style="background-color: white;height: 100%;">
        <span style="font-size: 32px;color: rgb(248, 87, 24);">College</span>
    </mat-toolbar>
</div>

<div class="modal-contents">

    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[5]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[6]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[7]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-contents">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[8]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[9]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[10]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-contents">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[11]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[12]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;width: 330px;" [src]="images[13]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-contents">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[14]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[15]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[16]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>


<div class="modal-contents">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[17]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[18]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[19]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-contents">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px; width: 330px;" [src]="images[23]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[24]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;width: 330px;" [src]="images[25]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal-contents">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[20]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[21]" class="img-carousel">
            </div>
            <div class="col-sm-4">
                <img style="height: 250px;" [src]="images[22]" class="img-carousel">
            </div>
        </div>
    </div>
    <br>
</div>

