import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent {
  images = [
    '../../assets/images/social-1.jpg',
    '../../assets/images/social-2.jpg',
    '../../assets/images/social-3.jpg',
    '../../assets/images/social-4.jpg',

  ]
}
