export class ModelMaster {

  constructor() { }

  //  Function to get Model for API Response
  public getLoginModel() {
    let model = {
      userName: '',
      password: ''
    };
    return model;
  }

  //  Function to get Model for API Response
  public getResponseModel() {
    let model = {
      status: 0,
      info: '',
      tat: 0,
      data: {}
    };
    return model;
  }

  //  Function to get Model for DB Query Result
  public getQueryModel() {
    let model = {
      status: 0,
      info: '',
      tat: 0,
      records: 0,
      data: {}
    };
    return model;
  }

  public getUserModel() {
    let model = {
      userID: '',
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      created_by: '',
    };
    return model;
  }

}
