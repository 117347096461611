<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">LEARNING RESOURCE CENTER</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{Infra[0]}}</blockquote>
      </div>
      <div class="col-sm-3" style="background-color:lavenderblush;">
        <img style="height: 100%;width: 100%;" [src]="images[0]" class="img-carousel">
      </div>
    </div>
  </div>
</div>

<div class="modal-contentss">
  <mat-toolbar style="background-color: white;">
    <span style="font-size: 32px;color: greenyellow;">FACILITIES</span>
  </mat-toolbar>
</div>


<div class="modal-contents">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3" style="background-color:lavenderblush;">
        <img style="height: 100%;width: 100%;" [src]="images[1]" class="img-carousel">
      </div>
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">CLASSROOMS</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{class[0]}}</blockquote>
      </div>
    </div>
  </div>
</div>

<div class="modal-contents">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">COMPUTING FACILITIES</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{computer[0]}}</blockquote>
      </div>
      <div class="col-sm-3" style="background-color:white;">
        <img style="height: 220px;width: 100%;" [src]="images[2]" class="img-carousel">
      </div>
    </div>
  </div>
</div>

<div class="modal-contents">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3" style="background-color:white;">
        <img style="height: 100%;width: 100%;" [src]="images[3]" class="img-carousel">
      </div>
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">LIBRARY FACILITIES</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{library[0]}}</blockquote>
      </div>
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12" style="background-color:white;">
        <h2 style="color: orangered;">TEACHING METHODOLOGY LAB</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{Teach[0]}} </blockquote>
      </div>
      <!-- <div class="col-sm-3" style="background-color:lavenderblush;">
        <img style="height: 100%;width: 100%;" [src]="images[4]" class="img-carousel">
      </div> -->
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12" style="background-color:white;">
        <h2 style="color: orangered;">CONFERENCE HALL</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{Hall[0]}} </blockquote>
      </div>
      <!-- <div class="col-sm-3" style="background-color:lavenderblush;">
        <img style="height: 100%;width: 100%;" [src]="images[4]" class="img-carousel">
      </div> -->
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">CAFETERIA</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{cafe[0]}} </blockquote>
      </div>
      <div class="col-sm-3" style="background-color:lavenderblush;">
        <img style="height: 100%;width: 100%;" [src]="images[4]" class="img-carousel">
      </div>
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3" style="background-color:white;">
        <img style="height: 100%;width: 100%;" [src]="images[5]" class="img-carousel">
      </div>
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">MEDICAL FACILITIES</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{medial[0]}}</blockquote>
      </div>
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">SCHOLARSHIP SCHEMES</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">
          To apply for scholarship under Post-matric Scholarship Scheme for SCs/OBCs students, please visit:<br>
          <a href="http://scholarship.up.nic.in/"> http://scholarship.up.nic.in/ </a><br>
          To apply for scholarship under Scholarship Scheme for Minorities students, and Central Sector Scholarship
          Scheme for College and University students, please visit:<br>
          <a href="http://scholarship.up.nic.in/"> http://scholarship.up.nic.in/ </a><br>
          To apply for AICTE’s scholarship scholarship for J & K students, please visit:<br>
          <a href="http://scholarship.up.nic.in/"> http://scholarship.up.nic.in/ </a><br></blockquote>
      </div>
      <div class="col-sm-3" style="background-color:white;">
        <img style="height: 100%;width: 100%;" [src]="images[6]" class="img-carousel">
      </div>
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3" style="background-color:lavenderblush;">
        <img style="height: 100%;width: 100%;" [src]="images[7]" class="img-carousel">
      </div>
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">TRANSPORT FACILITY</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">{{transport[0]}}</blockquote>
      </div>
    </div>
  </div>
</div>

<div class="modal-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-9" style="background-color:white;">
        <h2 style="color: orangered;">SPORTS COMPLEX</h2>
        <br>
        <mat-divider></mat-divider>
        <blockquote class="blockquote">
          The College has a state-of-art international standard multipurpose indoor sports stadium. It has a seating
          capacity of about 1200 spectators. The courts laid with wooden planks are used for Badminton, Basket Ball,
          Volley Ball, Judo, Wrestling, etc. Besides this, a separate room for chess and table tennis is also provided
          in the stadium.
          The College also has a hockey cum football field.
          The College has been excelling in sports for over two decades. Every year the College holds Sports
          Carnival, Inter-class matches and Sports Day. For proper training and grooming of players the College
          provides coaching facilities to the players for different games.</blockquote>
      </div>
      <div class="col-sm-3" style="background-color:white;">
        <img style="height: 250px;width: 100%;" [src]="images[8]" class="img-carousel">
      </div>
    </div>
  </div>
</div>