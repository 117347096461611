<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black;">
    <mat-toolbar-row>
      <marquee direction="right" style="font-size: 25px;color: blue;">BCA(Bachelor of Computer Application)</marquee>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: rgb(255, 255, 255);color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;">
        <div> Quick Facts </div>
      </span>
    </mat-toolbar-row>
    <mat-toolbar-row style="height: 100%;">

      <div class="container">
        <div class="row">
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13); size: 10vmin;">event_seat</mat-icon>Seats: 120</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">account_balance</mat-icon>Course-Duration: 3 years</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">school</mat-icon>Affilated-By: CCS University <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Meerut)</div>
          <div class="col-sm-6"><mat-icon style="color: rgb(255, 150, 13);">local_library</mat-icon>Medium: Regular</div>
        </div>
      </div>
      
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Course Details </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;"> Mode : <b>Full Time</b> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Duration: <b> 3
      years</b>
    &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Offered by: <b>Chaudhary Charan Singh University, Meerut</b><br>
    Bachelor of Computer Applications is a full time three years course offered by Chaudhary Charan
    Singh University, Meerut it is an undergraduate degree course in computer applications. With the rapid growth of IT
    industry in India, the demand of computer professional is increasing day by day. This increasing growth of IT
    industry has created a lot of opportunities for the computer graduates.
  </div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Eligibility Criteria </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">A person, who have passed Higher Secondary/Intermediate (10+2) any other examination
    recognized as equivalent there to with "PCM" or COMMERCE with "Math", 50% more than 50% marks,shall be eligible for
    the course.
  </div>
</div>

<div class="modal-content">
  <mat-toolbar style="background-color: white;color: black;">
    <mat-toolbar-row>
      <span style="font-size: 28px;text-align: center;">
        <div> Carrer Oppurtunities </div>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="margin-left: 15px;">
    The demand of IT professionals is increasing not only in India but also in abroad. After the completion of the
    course,
    the student can find a job in reputed IT companies like .IBM, Oracle, Infosys, and Google. The student can work as a
    system engineer, junior programmer, web developer or as a system administrator.<br><br>

    <b> System engineer </b> <br>
    => In the top companies like Infosys, Wipro, hp, Google. A system engineer develop, test and evaluate software,
    circuits and personal computer.<br>

    <b> Programmer</b> <br>
    => In various software development firms. The duty of programmer is to write code for software. A programmer is
    primarily works in the computer language such as Assembly, COBOL, C, C++, C#, Java, Lisp, Python, etc.<br>

    <b> Web developer</b> <br>
    => In various web designing companies and online digital marketing companies.
    A web developer is a programmer who specializes in the development of world wide web applications. The role of web
    developer is to build and maintain websites. A web developer must have skills in HTML/XHTML, CSS, PHP, JavaScript ,
    etc.<br>

    <b> System Administrator</b> <br>
    => In an organization including a bank, school or college. A System administrator is responsible for setting up and
    maintaining the system or server.<br>

    <b> Software developer </b> <br>
    => In the companies like Microsoft, Google, face book.
    The sole responsibility of software developer is to develop software that ease the tasks of the people and enable to
    perform work efficiently. A software developer also installs, test and maintains the software.
  </div>
</div>


