<!-- ##################### CAROUSEL ###################  -->
<div style="background-color: #ecd5e5;">
  <div class='container-fluid'>
    <div class='col-sm-12' style="padding: 2px;">
      <ngb-carousel *ngIf="images">
        <ng-template ngbSlide>
          <img style="height: 600px;" [src]="images[0]" class="img-carousel">
        </ng-template>

        <ng-template ngbSlide>
          <img style="height: 600px;" [src]="images[1]" class="img-carousel">
        </ng-template>

        <ng-template ngbSlide>
          <img style="height: 600px;" [src]="images[2]" class="img-carousel">
        </ng-template>

        <ng-template ngbSlide>
          <img style="height: 600px;" [src]="images[3]" class="img-carousel">
        </ng-template>

        <ng-template ngbSlide>
          <img style="height: 600px;" [src]="images[4]" class="img-carousel">
        </ng-template>
      </ngb-carousel>

      <!-- <div class="box stack-top col-sm-12s"
        style="margin-left: 1vmax;margin-top: 1vmax;background-color: rgba(32, 8, 4, 0.39);">
        <div style="color: red;font-size: 22px;font-family: Georgia;"> <b>Notice Board</b></div>

        <marquee direction="up" height="85%" scrollamount="5">
          <h3 style="color: rgb(255, 130, 47); font-family: cursive;font-size: 18px;" *ngFor="let notice of notices"> {{notice.notice}}</h3>
        </marquee>
        
      </div> -->

    </div>
  </div>

  <div class="modal-content">
    <!DOCTYPE html>
    <div class="container-fluid">
      <div class="row">

        <div class="col-sm-4 col-lg-4">
          <mat-card style="height: 520px;">
            <dt style="font-size: 24px;">Infrastructure</dt>
            <img routerLink='/infr' routerLinkActive="active" style="height: 250px;width: 100%;cursor: pointer;"
              [src]="images[0]" class="img-carousel">
            <div class="container"> {{Infrstructure[0]}}
              <p class="text-primary" routerLink='/infr' routerLinkActive="active" style="cursor: pointer;"> >>>>Read
                more
              </p>
            </div>
          </mat-card>
        </div>

        <div class="col-sm-4 col-lg-4">
          <mat-card style="height: 520px;">
            <dt style="font-size: 24px;">Scholarship</dt>
            <img style="height: 250px;width: 100%;" [src]="images[5]" class="img-carousel">
            <div class="container">
              - This college provide the <p class="text-primary">upto 90% scholarship</p> to each students.
            </div>
          </mat-card>
        </div>

        <div class="col-sm-4 col-lg-4">
          <mat-card style="height: 520px;">
            <dt style="font-size: 24px;">Location</dt>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3474.6815352065987!2d77.28533951483587!3d29.438102882106918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c2ea74200c9df%3A0xd48bf32845a77511!2sSilver%20Bells%20Institute%20For%20Higher%20Education%20Shamli!5e0!3m2!1sen!2sin!4v1586625305241!5m2!1sen!2sin"
              width="100%" height="250" frameborder="0" style="border:0"></iframe>
            <div class="container">
              {{Location[0]}}
            </div>
          </mat-card>
        </div>

        <div class="col-sm-4 col-lg-4">
          <mat-card class="card">
            <mat-card-header>
              <div mat-card-avatar [style.background-image]="'url(../../../assets/images/carousel/course.png)'"
                style="background-size: cover;">
              </div>
              <mat-card-title>BCA</mat-card-title>
              <mat-card-subtitle>
                <b>Seats: 120<br>
                  Course Duration: 3 years</b>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <b><span style="color: brown">Affiliated by :</span> CCS University</b><br>
              <b><span style="color: brown">Mode :</span> Regular</b><br>
              <b><span style="color: brown">Fee/Year : </span>22500</b>
              <!-- <b><span style="color: brown"> Total Fee : </span>67500 </b> -->
              <!-- <button style="color: brown;" mat-button routerLink='/fee'
                routerLinkActive="active">More</button> -->
              <br>
              <b><span style="color: brown">About Course : </span>{{Bcatext[0]}}</b><br>
              <br>
              <b><span style="color: brown">Career Opportunities : </span> {{Bcatext[1]}}</b><br>
              <p class="text-primary" routerLink='/bca' routerLinkActive="active" style="cursor: pointer;"> >>>>Read
                more
              </p>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-sm-4 col-lg-4">
          <mat-card class="card">
            <mat-card-header>
              <div mat-card-avatar [style.background-image]="'url(../../../assets/images/carousel/course.png)'"
                style="background-size: cover;">
              </div>
              <mat-card-title>B.Com.</mat-card-title>
              <mat-card-subtitle>
                <b>Seats: 120<br>
                  Course Duration: 3 years</b>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <b><span style="color: brown">Affiliated by :</span> CCS University</b><br>
              <b><span style="color: brown">Mode :</span> Regular</b><br>
              <b><span style="color: brown">Fee/Year : </span>8000</b>
              <!-- <b><span style="color: brown"> Total Fee : </span>24000 </b> -->
              <!-- <button style="color: brown;" mat-button routerLink='/fee'
                routerLinkActive="active">More</button> -->
              <br>
              <b><span style="color: brown">About Course : </span> {{Bcomtext[0]}} </b><br>
              <br>
              <b><span style="color: brown">Career Opportunities : </span> {{Bcomtext[1]}} </b><br>
              <p class="text-primary" routerLink='/bcom' routerLinkActive="active" style="cursor: pointer;"> >>>>Read
                more
              </p>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-sm-4 col-lg-4">
          <mat-card class="card">

            <mat-card-header>
              <div mat-card-avatar [style.background-image]="'url(../../../assets/images/carousel/course.png)'"
                style="background-size: cover;">
              </div>
              <mat-card-title>BBA</mat-card-title>
              <mat-card-subtitle>
                <b>Seats: 60<br>
                  Course Duration: 3 years</b>
              </mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <b><span style="color: brown">Affiliated by :</span> CCS University</b><br>
              <b><span style="color: brown">Mode :</span> Regular</b><br>
              <b><span style="color: brown">Fee/Year : </span>22500</b>
              <br>
              <b><span style="color: brown">About Course : </span> {{Bbatext[0]}} </b><br>
              <br>
              <b><span style="color: brown">Career Opportunities : </span> {{Bbatext[1]}} </b><br>
              <p class="text-primary" routerLink='/bba' routerLinkActive="active" style="cursor: pointer;"> >>>>Read
                more
              </p>
            </mat-card-content>

          </mat-card>
        </div>
        
        <div class="col-sm-12 col-lg-12">
          <mat-card class="card">

            <mat-card-header>
              <div mat-card-avatar [style.background-image]="'url(../../../assets/images/carousel/course.png)'"
                style="background-size: cover;">
              </div>
              <mat-card-title>B.ED</mat-card-title>
              <mat-card-subtitle>
                <b>Seats: 100<br>
                  Course Duration: 2 years</b>
              </mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <b><span style="color: brown">Affiliated by :</span> CCS University</b><br>
              <b><span style="color: brown">Mode :</span> Regular</b><br>
              <b><span style="color: brown">First Year : </span>51250</b>&nbsp;&nbsp;
              <b><span style="color: brown">Second Year : </span>30000</b>
              <br>
              <b><span style="color: brown">About Course : </span> {{BEDTest[0]}} </b><br>
              <br>
              <b><span style="color: brown">Career Opportunities : </span> {{BEDTest[1]}} </b><br>
              <p class="text-primary" routerLink='/bba' routerLinkActive="active" style="cursor: pointer;"> >>>>Read
                more
              </p>
            </mat-card-content>

          </mat-card>
        </div>

      </div>
    </div>
  </div>


</div>