import { Injectable } from '@angular/core';
import { HttpMasterService } from '../http/http-master.service';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    public httpMaster: HttpMasterService,
    public baseUrl: BaseService
  ) { }

  async postContactForm(payload) {
    // alert('payload' + JSON.stringify(payload))
    let path = '/test/silver/contact';
    let serverUrl = this.baseUrl.getBaseUrl() + path;
    let result = await this.httpMaster.postRequest(serverUrl, payload);
    // alert(JSON.stringify(result));
    return result;
  }

}
