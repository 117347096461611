<div class="container-fluid" style="width: 100%;" style="background-color: black;color: white;">

  <div class="row" style="background-color:#14bdee">
    <div class="col-sm-3"></div>
    <div class=" col-sm-12 col-md-6 col-lg-6 d-flex justify-content-start">
      <span style="color:white; font-size: 28px; margin-top: 7px;font-family: Georgia;margin-left: 80px;">
        Silver Bells Institute For Higher<span> Education</span>
      </span>
    </div>

  </div>

  <div class="row" >
    <div class="col-sm-2"></div>
    <button mat-button routerLink='/home' routerLinkActive="active" style="font-size: 16px;">
      <mat-icon>home</mat-icon>Home
    </button>
    <button mat-button routerLink='/aboutus' routerLinkActive="active" style="font-size: 16px;" >About us</button>
    <button mat-button [matMenuTriggerFor]="courses" (mouseenter)="clickOnHover()" style="font-size: 16px;">Courses</button>
    <button mat-button [matMenuTriggerFor]="academics" style="font-size: 16px;">Academics</button>
    <button mat-button routerLink='/infr' routerLinkActive="active" style="font-size: 16px;">Infrastructure</button>
    <button mat-button routerLink='/gallery' routerLinkActive="active" style="font-size: 16px;">Gallery</button>
    <button mat-button routerLink='/document' routerLinkActive="active" style="font-size: 16px;">Document</button>
    <button mat-button routerLink='/contact' routerLinkActive="active" style="font-size: 16px;">Contact us</button>
    <!-- <button mat-button routerLink='/credit' routerLinkActive="active" style="font-size: 16px;">Credits</button> -->
  </div>

  <mat-menu #courses="matMenu">
    <button mat-menu-item routerLink='/bca' routerLinkActive="active"><b>BCA</b> - Bachelor of Computer
      Application</button>
    <button mat-menu-item routerLink='/bba' routerLinkActive="active"><b>BBA</b> - Bachelor of Business
      Administration</button>
    <button mat-menu-item routerLink='/bcom' routerLinkActive="active"><b>B.Com</b> - Bachelor of Commerce</button>
    <button mat-menu-item routerLink='/bed' routerLinkActive="active"><b>B.Ed</b> - Bachelor of Education</button>
  </mat-menu>

  <mat-menu #academics="matMenu">
    <button mat-menu-item routerLink='/admission' routerLinkActive="active">Admission</button>
    <button mat-menu-item routerLink='/fee' routerLinkActive="active">Fee Structure</button>
    <button mat-menu-item routerLink='/syllabus' routerLinkActive="active">Syllabus</button>
  </mat-menu>

  <mat-menu #downloads="matMenu">
    <button mat-menu-item routerLink='/aman' routerLinkActive="active">Certificates</button>
    <button mat-menu-item routerLink='/repo' routerLinkActive="active">Documents</button>
    <button mat-menu-item>Forms</button>
  </mat-menu>

</div>

<!-- ********************************************************************* -->

<!-- Side menu Navigation -->
<mat-sidenav-container style="min-height: 100%;">
  <!-- <mat-sidenav #drawer mode="side" opened role="navigation" style="min-width: 20%;" > -->

  <mat-sidenav-content>
    <router-outlet> </router-outlet>
    <br>
  </mat-sidenav-content>

</mat-sidenav-container>